import classNames from 'classnames';
import 'rc-datetime-picker/dist/picker.css';
import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CheckedStyle, CirclePlus, DeleteBin, play, selection } from '../../../../assets/images/icons';
import { useBlueprintComponentsContext } from '../../../../context/blueprintsComponents.context';
import { getAllPositions, getCompanyDepartments } from '../../../../services/company.service';
import { getProcessById } from '../../../../services/processes.service';
import { getProducts } from '../../../../services/products.service';
import { actionsArray, controlledUnsubscription, currency_list, dropdownComponentSelectionType, fileExtensions, generateNewUUID } from '../../../../shared/utility';
import { Button, ModalR, MultiselectWithSearchR, RenderBooleansOptions, RenderTitle, SelectD, SelectWithSearch, Spinner, UnfocusableWrapper, RenderYesNoSwitcher } from '../../../common';

import { MultiSelectWithCheckboxesAndNestedItems_v2 } from '../../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';

import cls from './FieldOptionsModal.module.scss';
import { first } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import ReactSlider from 'react-slider'
const R = require('ramda');

export const FieldOptionsModal = props => {
	const { onClose, isOpen, leftControlLoading, leftControlAction, centralControlAction, centralControlLoading, modalStyle, allModules, blueprints, selectedFields, components, products, companyProcesses, readOnly = false } = props;
	const [bpContext] = useBlueprintComponentsContext();
	const { field_type = {}, id, value, options } = bpContext?.selectedFieldFromSelectedBlueprintComponent || {};
	const [fieldValue, setFieldValue] = useState(value);
	const [triggerRTValidation, setTriggerRTValidation] = useState(false);
	const [fieldOption, setFieldOption] = useState({ ...options });
	const [validationErrors, setValidationErrors] = useState({});
	const settingFieldValue = val => {
		setFieldValue(val);
	};
	const [intervalVal, setIntervalVal] = useState(options?.interval);
	const [fieldlVal, setFieldVal] = useState(options?.field);
	const [userFields,] = useState([
		{ label: 'Firstname', value: 'first_name' },
		{ label: 'Lastname', value: 'last_name' },
		{ label: 'Username', value: 'username' },
		{ label: 'Email', value: 'email' },
		{ label: 'Address', value: 'address' },
		{ label: 'City', value: 'city' },
		{ label: 'Postal', value: 'postal' },
		{ label: 'Phone', value: 'phone' },
		{ label: 'Timezone', value: 'timezone' }
	]);

	const validContent = useCallback(() => {
		let errors = { general: '', name: '', list: '', type: '' };
		if (fieldValue?.trim()?.length < 1) errors.name = 'Element name must have at least one letter character';
		if (field_type && R.has('options')(field_type)) {
			field_type?.options?.forEach(i => {
				if (typeof i !== 'object') {
					if (!R.has(i?.toString())(fieldOption)) {
						errors.general = 'Mandatory fields are not completed,';
					}
					if (!fieldOption[i] || (fieldOption?.[i] && fieldOption[i]?.length < 1)) {
						errors[i] = ` ${i} was not provided`;
					}
				}
			});
		}
		setValidationErrors(errors);
		if (Object.values(errors).every(x => x === '')) {
			return true;
		} else {
			return false;
		}
	}, [fieldOption, fieldValue, field_type, options]);
	const realTimeValidation = useCallback(() => {
		if (triggerRTValidation) {
			validContent();
		}
	}, [triggerRTValidation, validContent]);
	useEffect(() => {
		realTimeValidation();
	}, [realTimeValidation]);

	const onSavePressed = useCallback(() => {
		setTriggerRTValidation(true);
		if (validContent()) {
			centralControlAction?.({ id, value: fieldValue, options: fieldOption });
		}
	}, [centralControlAction, fieldOption, fieldValue, id, validContent]);
	return (
		<ModalR isOpen={isOpen} blured={true} scrollable={true} shadowedContainerStyle={modalStyle}>
			<div className={cls.host}>
				<div className={cls.target}>
					<p className={cls.title}>{`edit ${field_type?.name}`}</p>
					<OptionSection errorMessage={Object.values(validationErrors).length > 0 ? Object.values(validationErrors).filter(st => st !== '') : ''} />
					<OptionSection >
						<RenderTitle title={'Field Name'} />
						<input type="text" name="element-name" value={fieldValue} onChange={e => settingFieldValue(e.target.value)} className={cls.elementTextInput} readOnly={field_type.name === 'Template' ? true : false} />
					</OptionSection>
					{field_type?.name?.toLowerCase() === 'number' && (
						<>
							<NumberOptions
								validationErrors={validationErrors}
								defaultValue={options?.type == 'numeric'}
								onChangeType={value => {
									setFieldOption({ type: value });
								}}
							/>

						</>
					)}
					{field_type?.name?.toLowerCase() === 'url' && (
						<URLOptions
							validationErrors={validationErrors}
							defaultType={options?.type == 'image'}
							defaultValue={options?.value ?? ''}
							onChangeType={value => {
								setFieldOption({ ...fieldOption, type: value });
							}}
							onChangeValue={value => {
								setFieldOption({ ...fieldOption, value: value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'checkbox' && (
						<CheckboxOptions
							validationErrors={validationErrors}
							defaultValue={options?.value ?? ''}
							onChangeValue={value => {
								setFieldOption({ ...fieldOption, value: value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'phone' && <PhonePrefix validationErrors={validationErrors} countryCode={options?.country_code} defaultValue={options?.country_prefix + ' ' + options?.country_name} onChange={val => setFieldOption(val)} />}
					{field_type?.name?.toLowerCase() === 'currency' && (
						<CurencySelect
							validationErrors={validationErrors}
							defaultValue={R.find(R.propEq('value', options?.currency_value))(currency_list)}
							onCurrencySelected={c => {
								setFieldOption({ currency_type: c?.label, currency_value: c?.value, symbol: c?.symbol });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'action button' && (
						<ActionSelect
							validationErrors={validationErrors}
							defaultValue={options?.action}
							onActionSelected={c => {
								setFieldOption({ action: c?.value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'value button' && (
						<>
							<OptionSection>
								<RenderTitle title={'Button label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.label}

										onChange={e => {
											setFieldOption({ ...fieldOption, label: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Button Image (Optional)'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.image}

										onChange={e => {
											setFieldOption({ ...fieldOption, image: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Value to set'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.value}
										onChange={e => {
											setFieldOption({ ...fieldOption, value: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<RenderYesNoSwitcher
								{...{
									id: 'Use selection as decision for step',
									title: 'Use selection as decision for step?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={fieldOption?.decision}
								toggleSelected={val => {
									if (val) {
										setFieldOption({ ...fieldOption, decision: true });
									} else {
										setFieldOption({ ...fieldOption, decision: false, jump1: undefined });
									}

								}}
							/>

							{fieldOption?.decision && <OptionSection errorMessage={validationErrors?.position_id}>
								<RenderTitle title={'Process where to look for step'} />
								<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
									<SelectD
										items={
											companyProcesses.map(pro => {
												return { label: pro.name, value: pro.id, process: pro }
											})
										}
										defaultSelected={{ label: companyProcesses.find(pro => pro.id === fieldOption?.process)?.name }}
										className={cls.dropdownSelectComponent}
										tmiClassName={cls.dropdownInput}
										molclassname={cls.dropdownSelectMenuOverlayList}
										moliclassname={cls.dropdownSelectMenuOverlayListItem}
										onChange={selection => {
											setFieldOption({ ...fieldOption, process: selection.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>}
							{fieldOption?.decision && fieldOption.process && <OptionSection errorMessage={validationErrors?.position_id}>
								<RenderTitle title={'Selection jumps to step'} />
								<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
									<SelectD
										items={
											companyProcesses.find(pro => pro.id === fieldOption?.process)?.steps?.map(step => {
												return { label: step.name, value: step.id, step: step }
											})
										}
										defaultSelected={{ label: companyProcesses.find(pro => pro.id === fieldOption?.process)?.steps?.find(step => step.id === fieldOption.jump1)?.name }}
										className={cls.dropdownSelectComponent}
										tmiClassName={cls.dropdownInput}
										molclassname={cls.dropdownSelectMenuOverlayList}
										moliclassname={cls.dropdownSelectMenuOverlayListItem}
										onChange={selection => {
											setFieldOption({ ...fieldOption, jump1: selection.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>}
						</>
					)}
					{field_type?.name?.toLowerCase() === 'profile' && (
						<ProfileSelect
							validationErrors={validationErrors}
							defaultValue={options?.type}
							defaultOption={fieldOption}
							onProfileSelected={c => {
								setFieldOption({ type: c?.value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'upload' && (
						<UploadSelect
							validationErrors={validationErrors}
							defaultMaxSize={+options?.max_file_size / 1024 ?? 50 * 1024}
							onMaxFileSizeChanged={maxFileSize => {
								setFieldOption({ ...fieldOption, max_file_size: +maxFileSize * 1024 });
							}}
							defaultList={options.accepted_mimes}
							onListSelected={list => {
								setFieldOption({ ...fieldOption, accepted_mimes: list });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'date' && (
						<DateOptions
							validationErrors={validationErrors}
							defaultValue={options?.type == 'single'}
							defaultAuto={options?.auto}
							onChangeType={value => {
								setFieldOption({ ...fieldOption, type: value });
							}}
							onExceeded={value => {
								setFieldOption({ auto: value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'people' && (
						<>
							<PeopleSelect
								validationErrors={validationErrors}
								allModules={allModules}
								defaultType={options?.type}
								defaultAssign={options?.assign}
								defaultEmail={options?.email}
								defaultCalendar={options?.calendar}
								defaultSlider={options?.slider}
								onChangeSlider={selection => {
									setFieldOption({ ...fieldOption, slider: selection });
								}}
								onCalendar={selection => {
									setFieldOption({ ...fieldOption, calendar: selection });
								}}
								defaultModule={allModules
									.map(elem => {
										return { label: elem.name, value: elem.id, module: elem };
									})
									.find(i => i?.value === (options?.module_id ?? ''))}
								defaultPositions={options?.position_id ?? []}
								onChangeType={selection => {
									setFieldOption({ ...fieldOption, type: selection });
								}}
								onChangeEmail={selection => {
									setFieldOption({ ...fieldOption, email: selection });
								}}
								onChangeAssign={selection => {
									setFieldOption({ ...fieldOption, assign: selection });
								}}
								onChangeList={list => {
									if (list.length > 0) {
										setFieldOption({ ...fieldOption, position_id: [...list] });
									} else {
										delete fieldOption.position_id;
									}
								}}
								onModuleSelected={selection => {
									setFieldOption({ ...fieldOption, module_id: selection.value });
								}}
								options={options}
								onOptionsSelected={value => {
									setFieldOption(value);
								}}
								fieldOption={fieldOption}
							/>
							{fieldOption?.position_id &&
								<OptionSection>
									<RenderTitle title={'Field in users to use as filter'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<SelectD
											items={userFields}
											defaultSelected={userFields.find(fie => fie.value === fieldOption.filter)}
											className={cls.dropdownSelectComponent}
											tmiClassName={cls.dropdownInput}
											molclassname={cls.dropdownSelectMenuOverlayList}
											moliclassname={cls.dropdownSelectMenuOverlayListItem}
											onChange={selection => {
												setFieldOption({ ...fieldOption, filter: selection.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>}
							{fieldOption?.position_id &&
								<StatusSelect
									title={'Filter Selections'}
									validationErrors={validationErrors}
									defaultList={fieldOption?.dropdown?.length > 0 ? fieldOption?.dropdown : undefined}
									onListSelected={list => {
										setFieldOption({ ...fieldOption, dropdown: list });
									}}
								/>}
							<div style={{ display: fieldOption?.calendar ? '' : 'none' }}>
								<OptionSection>
									<RenderTitle title={'Header label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.header}
											onChange={e => {
												setFieldOption({ ...fieldOption, header: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Title label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.title ?? 'Event Name'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, title: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Description label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.description ?? 'Description'}

											onChange={e => {
												setFieldOption({ ...fieldOption, description: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Assignee label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.assignee ?? 'Assigned to'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, assignee: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Start date label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.start ?? 'Start Date'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, start: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'End date label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.end ?? 'End Date'}
											onChange={e => {
												setFieldOption({ ...fieldOption, end: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Value label to use (Optional)'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.valueL}
											onChange={e => {
												setFieldOption({ ...fieldOption, valueL: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
									<p className={cls.optionSettings}>
										{'Note: If set, ensure label is set in calendar'}
									</p>
								</OptionSection>
								<StatusSelect
									validationErrors={validationErrors}
									defaultList={options?.list?.length > 0 ? options?.list : undefined}
									onListSelected={list => {
										setFieldOption({ ...fieldOption, list: list });
									}}
								/>
							</div>

						</>
					)}
					{field_type?.name?.toLowerCase() === 'positions' && (
						<>
							<PositionsSelect
								validationErrors={validationErrors}
								allModules={allModules}
								defaultType={options?.type}
								defaultAssign={options?.assign}
								defaultEmail={options?.email}
								defaultCalendar={options?.calendar}
								defaultSlider={options?.slider}
								onChangeSlider={selection => {
									setFieldOption({ ...fieldOption, slider: selection });
								}}
								onCalendar={selection => {
									setFieldOption({ ...fieldOption, calendar: selection });
								}}
								defaultModule={allModules
									.map(elem => {
										return { label: elem.name, value: elem.id, module: elem };
									})
									.find(i => i?.value === (options?.module_id ?? ''))}
								defaultPositions={options?.position_id ?? []}
								onChangeType={selection => {
									setFieldOption({ ...fieldOption, type: selection });
								}}
								onChangeEmail={selection => {
									setFieldOption({ ...fieldOption, email: selection });
								}}
								onChangeAssign={selection => {
									setFieldOption({ ...fieldOption, assign: selection });
								}}
								onChangeList={list => {
									if (list.length > 0) {
										setFieldOption({ ...fieldOption, position_id: [...list] });
									} else {
										delete fieldOption.position_id;
									}
								}}
								onModuleSelected={selection => {
									setFieldOption({ ...fieldOption, module_id: selection.value });
								}}
								options={options}
								onOptionsSelected={value => {
									setFieldOption(value);
								}}
								fieldOption={fieldOption}
							/>
							<div >
								<RenderYesNoSwitcher
									{...{
										id: 'Fetch users when item is selected',
										title: 'Fetch users when item is selected?',
										ungrouped: true,
										withAckButton: false,
									}}
									style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
									value={fieldOption?.fetch}
									toggleSelected={val => {
										if (val) {
											setFieldOption({ ...fieldOption, fetch: true });
										} else {
											setFieldOption({ ...fieldOption, fetch: false });
										}

									}}
								/>
								{/* <OptionSection>
									<RenderTitle title={'Fetch users when item is selected'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.fetch}
											onChange={e => {
												setFieldOption({ ...fieldOption, header: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection> */}
							</div>
							<div style={{ display: fieldOption?.calendar ? '' : 'none' }}>
								<OptionSection>
									<RenderTitle title={'Header label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.header}
											onChange={e => {
												setFieldOption({ ...fieldOption, header: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Title label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.title ?? 'Event Name'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, title: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Description label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.description ?? 'Description'}

											onChange={e => {
												setFieldOption({ ...fieldOption, description: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Assignee label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.assignee ?? 'Assigned to'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, assignee: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Start date label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.start ?? 'Start Date'}
											//name={'Equivalent wallet value to apply'}
											onChange={e => {
												setFieldOption({ ...fieldOption, start: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'End date label to use'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.end ?? 'End Date'}
											onChange={e => {
												setFieldOption({ ...fieldOption, end: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
								<OptionSection>
									<RenderTitle title={'Value label to use (Optional)'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'text'}
											className={cls.dropDownNewListItemInput}
											value={fieldOption?.valueL}
											onChange={e => {
												setFieldOption({ ...fieldOption, valueL: e.target.value });
											}}
										/>
									</UnfocusableWrapper>
									<p className={cls.optionSettings}>
										{'Note: If set, ensure label is set in calendar'}
									</p>
								</OptionSection>
								<StatusSelect
									validationErrors={validationErrors}
									defaultList={options?.list?.length > 0 ? options?.list : undefined}
									onListSelected={list => {
										setFieldOption({ ...fieldOption, list: list });
									}}
								/>
							</div>

						</>
					)}
					{field_type?.name?.toLowerCase() === 'dropdown' && (
						<DropdownSelect
							validationErrors={validationErrors}
							defaultType={dropdownComponentSelectionType.find(i => i?.value == (options?.type ?? 'single'))}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							defaultPr={options?.process ?? ''}
							defaultJ1={options?.jump1 ?? ''}
							defaultJ2={options?.jump2 ?? ''}
							onTypeSelected={selection => {
								setFieldOption({ ...fieldOption, type: selection.value });
							}}
							onListSelected={list => {
								setFieldOption({ ...fieldOption, list: list });
							}}
							onJump1Selected={value => {
								setFieldOption({ ...fieldOption, jump1: value });
							}}
							onJump2Selected={value => {
								setFieldOption({ ...fieldOption, jump2: value });
							}}
							onProcSelected={value => {
								setFieldOption({ ...fieldOption, process: value });
							}}
							companyProcesses={companyProcesses}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'status' && (
						<StatusSelect
							validationErrors={validationErrors}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							onListSelected={list => {
								setFieldOption({ ...fieldOption, list: list });
							}}
						/>
					)}
					{(field_type?.name?.toLowerCase() === 'list' || field_type?.name?.toLowerCase() === 'module list') && (
						<DropdownSelectList
							allModules={allModules}
							validationErrors={validationErrors}
							defaultType={{ label: options?.type ?? 'Table', value: options?.type ?? 'Table' }}
							defaultTypeX={options}
							defaultModule={allModules
								.map(elem => {
									return { label: elem.name, value: elem.id, module: elem };
								})
								.find(i => i?.value === (options?.module_id ?? ''))}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							onTypeSelected={selection => {
								setFieldOption({ ...fieldOption, type: selection.value });
							}}
							onModuleSelected={selection => {
								setFieldOption({ ...fieldOption, module_id: selection.module.id });
							}}
							onChangeList={list => {
								setFieldOption({ ...fieldOption, list: [...list] });
							}}
							multiSelectLabel={
								options?.module_id === ''
									? ''
									: allModules
										.map(elem => {
											return { label: elem.name, value: elem.id, module: elem };
										})
										.find(i => i?.value === (options?.module_id ?? ''))?.label
							}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'transaction list' && (
						<>
							<OptionSection>
								<RenderTitle title={'List ID'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={options?.id}
										onChange={e => {
											setFieldOption({ ...fieldOption, id: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<TransactionSelectList
								blueprints={blueprints}
								allModules={allModules}
								companyProcesses={companyProcesses}
								validationErrors={validationErrors}

								defaultType={{ label: options?.type ?? 'Table', value: options?.type ?? 'Table' }}
								options={options}
								defaultMap={options?.map ?? {}}
								defaultModule={allModules
									.map(elem => {
										return { label: elem.name, value: elem.id, module: elem };
									})
									.find(i => i?.value === (options?.module_id ?? ''))}
								defaultList={options?.list?.length > 0 ? options?.list : undefined}
								onTypeSelected={selection => {
									setFieldOption({ ...fieldOption, type: selection.value });
								}}
								onModuleSelected={selection => {
									setFieldOption({ ...fieldOption, module_id: selection.module.id });
								}}
								onChangeMapping={map => {
									setFieldOption({ ...fieldOption, map: map });
								}}
								multiSelectLabel={
									options?.module_id === ''
										? ''
										: allModules
											.map(elem => {
												return { label: elem.name, value: elem.id, module: elem };
											})
											.find(i => i?.value === (options?.module_id ?? ''))?.label
								}
								setFieldOption={setFieldOption}
							/>
						</>
					)}
					{(field_type?.name?.toLowerCase() === 'step list' || field_type?.name?.toLowerCase() === 'component list') && (
						<InfoSelect
							blueprints={blueprints.filter(i => i?.id !== (options?.component_id ?? ''))}
							validationErrors={validationErrors}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							defaultType={blueprints
								.map(elem => {
									return { label: elem.name, value: elem.id, module: elem };
								})
								.find(i => i?.value === (options?.component_id ?? ''))}
							onTypeSelected={selection => {
								setFieldOption({ ...fieldOption, component_id: selection.component.id });
							}}
							defaultKey={options?.key ?? 'Key'}
							onKeySelected={selection => {
								setFieldOption({ ...fieldOption, key: selection });
							}}
							onChangeList={list => {
								setFieldOption({ ...fieldOption, list: [...list] });
							}}
							multiSelectLabel={
								options?.component_id === ''
									? ''
									: blueprints
										.map(elem => {
											return { label: elem.name, value: elem.id, module: elem };
										})
										.find(i => i?.value === (options?.component_id ?? ''))?.label
							}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'product' && (
						<DropdownSelectProducts
							products={products}
							validationErrors={validationErrors}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							onChangeList={list => {
								setFieldOption({ ...fieldOption, list: [...list] });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'record' && (
						<>
							<RecordsSelect
								defaultFilters={options?.filters}
								validationErrors={validationErrors}
								field_type={field_type}
								blueprints={blueprints}
								nameValues={options?.names?.length > 0 ? options?.names : []}
								defaultType={options?.selection?.length > 0 ? { label: options?.selection } : undefined}
								defaultList={options?.list?.length > 0 ? options?.list : []}
								defaultValue={{
									label:
										bpContext.allBlueprintComponents.find(item => item.id === bpContext.selectedFieldFromSelectedBlueprintComponent.options?.component_id) === undefined
											? ''
											: bpContext.allBlueprintComponents.find(item => item.id === bpContext.selectedFieldFromSelectedBlueprintComponent.options?.component_id).name,
								}}
								defaultOutputField={options?.output?.length > 0 ? { label: options?.output } : undefined}
								defaultGlobal={options?.global}
								onGlobalSelected={value => {
									setFieldOption({ ...fieldOption, global: value });
								}}
								onListSelected={values => {
									setFieldOption({ ...fieldOption, list: values });
								}}
								onComponentSelected={selection => {
									setFieldOption({ ...fieldOption, component_id: selection.blueprint.id });
								}}
								onTypeSelected={value => {
									setFieldOption({ ...fieldOption, selection: value });
								}}
								onNamesSelected={values => {
									setFieldOption({ ...fieldOption, names: values });
								}}
								onOutputFieldSelected={value => {
									setFieldOption({ ...fieldOption, output: value });
								}}
								onFilterList={filters => {
									setFieldOption({ ...fieldOption, filters: filters });
								}}
							/>
							<OptionSection errorMessage={validationErrors?.type}>
								<RenderTitle title={'Optional: Conduct lookup from these transactions:'} />
								<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
									<SelectD
										items={allModules.map(mod=>{
											return {label: mod.name, value: mod.id}
										})}
										defaultSelected={allModules.map(mod=>{
											return {label: mod.name, value: mod.id}
										}).find(mod=> mod.value === options?.module_id)}
										className={cls.dropdownSelectComponent}
										tmiClassName={cls.dropdownInput}
										molclassname={cls.dropdownSelectMenuOverlayList}
										moliclassname={cls.dropdownSelectMenuOverlayListItem}
										onChange={selection => {
											setFieldOption({ ...fieldOption, module_id: selection?.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'googleai assistant' && (
						<SmartQuery
							defaultCurrent={options?.current}
							defaultDescription={options?.description}
							defaultValue={options?.analyze}
							defaultFixed={options?.fixed}
							defaultModule={{
								label: allModules.find(mod => mod.id === options?.module_id) === undefined ? '' : allModules.find(mod => mod.id === options?.module_id).name,
							}}
							defaultModule2={{
								label: allModules.find(mod => mod.id === options?.module2_id) === undefined ? '' : allModules.find(mod => mod.id === options?.module2_id).name,
							}}
							defaultModule3={{
								label: allModules.find(mod => mod.id === options?.module3_id) === undefined ? '' : allModules.find(mod => mod.id === options?.module3_id).name,
							}}
							setFieldValue={value => {
								setFieldOption({ ...fieldOption, analyze: value });
							}}
							setDefaultCurrent={value => {
								setFieldOption({ ...fieldOption, current: value });
							}}
							setDefaultDescription={value => {
								setFieldOption({ ...fieldOption, description: value });
							}}
							onModule1Select={value => {
								setFieldOption({ ...fieldOption, module_id: value?.value });
							}}
							onModule2Select={value => {
								setFieldOption({ ...fieldOption, module2_id: value?.value });
							}}
							onModule3Select={value => {
								setFieldOption({ ...fieldOption, module3_id: value?.value });
							}}
							setDefaultFixed={value => {
								setFieldOption({ ...fieldOption, fixed: value });
							}}
							validationErrors={validationErrors}
							allModules={allModules}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'value' && (
						<ValueSelect
							field_type={field_type}
							allModules={allModules}
							components={components}
							defaultModule={options?.module_id?.length > 0 ? { label: allModules.find(mod => mod.id === options?.module_id).name, value: options?.module_id } : undefined}
							defaultOutput={components
								?.filter(com => com.module_id === options?.module_id)
								.map(com => {
									return { label: `${com.title}`, value: com.id };
								})}
							defaultValue={{
								label: options?.value,
								slabel:
									bpContext.allBlueprintComponents.find(item => item.id === bpContext.selectedFieldFromSelectedBlueprintComponent?.options?.component_id) === undefined
										? ''
										: bpContext.allBlueprintComponents.find(item => item.id === bpContext.selectedFieldFromSelectedBlueprintComponent.options.component_id).name,
							}}
							onModuleSelected={selection => {
								setFieldOption({ ...fieldOption, module_id: selection.value });
							}}
							onOutputSelected={selection => {
								setFieldOption({ ...fieldOption, component_id: selection.value });
							}}
							onFieldValueSelected={selection => {
								setFieldOption({ ...fieldOption, value: selection.value });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'formula' && (
						<>
							<DropdownSelectFormulas
								selectedFields={selectedFields}
								validationErrors={validationErrors}
								defaultValue={options?.operator?.length > 0 ? options?.operator : undefined}
								defaultList={options?.list?.length > 0 ? options?.list : undefined}
								defaultSearchText={options?.searchtext?.length > 0 ? options?.searchtext : undefined}
								onOperatorSelected={value => {
									setFieldOption({ ...fieldOption, operator: value });
								}}
								onChangeList={list => {
									setFieldOption({ ...fieldOption, list: [...list] });
								}}
								onChangeSearchText={value => {
									setFieldOption({ ...fieldOption, searchtext: value });
								}}
								blueprints={blueprints}
							/>
							{fieldOption?.operator === 'freesum' &&
								<StatusSelect
									title={'Add fields to compute'}
									validationErrors={validationErrors}
									defaultList={options?.list?.length > 0 ? options?.list : undefined}
									onListSelected={list => {
										setFieldOption({ ...fieldOption, list: list });
									}}
								/>}
						</>
					)}

					{field_type?.name?.toLowerCase() === 'list filter' && (
						<ReportsFilter
							selectedFields={selectedFields}
							validationErrors={validationErrors}
							defaultField={options?.field?.length > 0 ? options?.field : undefined}
							defaultValue={options?.value?.length > 0 ? options?.value : undefined}
							onChangeField={field => {
								setFieldOption({ ...fieldOption, field: field });
							}}
							onChangeValue={value => {
								setFieldOption({ ...fieldOption, value: value });
							}}
							blueprints={blueprints}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'connector' && (
						<DropdownSelectConnector
							selectedFields={selectedFields}
							validationErrors={validationErrors}
							defaultComponent1={options?.component1_id?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.component1_id).name } : undefined}
							defaultComponent2={options?.component2_id?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.component2_id).name } : undefined}
							defaultComponent3={options?.component3_id?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.component3_id).name } : undefined}
							defaultCommon12={options?.common_field_12?.length > 0 ? { label: options?.common_field_12 } : undefined}
							defaultInputComponent1={options?.component1_input?.length > 0 ? { label: options?.component1_input } : undefined}
							defaultCommon23={options?.common_field_23?.length > 0 ? { label: options?.common_field_23 } : undefined}
							defaultOutputs={options?.outputs?.length > 0 ? options?.outputs : []}
							onComponent1InputSelected={value => {
								setFieldOption({ ...fieldOption, component1_input: value });
							}}
							onComponent1Selected={value => {
								setFieldOption({ ...fieldOption, component1_id: value });
							}}
							onComponent2Selected={value => {
								setFieldOption({ ...fieldOption, component2_id: value });
							}}
							onCommon12Selected={value => {
								setFieldOption({ ...fieldOption, common_field_12: value });
							}}
							onComponent3Selected={value => {
								setFieldOption({ ...fieldOption, component3_id: value });
							}}
							onCommon23Selected={value => {
								setFieldOption({ ...fieldOption, common_field_23: value });
							}}
							onOutputsSelected={outputs => {
								setFieldOption({ ...fieldOption, outputs: [...outputs] });
							}}
							bpContext={bpContext}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'payments' && (
						<DropdownSelectPayments
							validationErrors={validationErrors}
							defaultValue={options?.type?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.type).name } : undefined}
							defaultSrcName={options?.paymentField?.length > 0 ? { label: options?.paymentField } : undefined}
							onSourceSelected={value => {
								setFieldOption({ ...fieldOption, type: value });
							}}
							onSourceLabel={value => {
								setFieldOption({ ...fieldOption, paymentField: value });
							}}
							bpContext={bpContext}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							onListSelected={list => {
								setFieldOption({ ...fieldOption, list: list });
							}}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'lookup' && (
						<DropdownSelectLookup
							selectedFields={selectedFields}
							validationErrors={validationErrors}
							defaultGlobal={options?.global?.length > 0 ? options?.global : undefined}
							defaultValue={options?.source_id?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.source_id).name } : undefined}
							defaultTarget={options?.target_id?.length > 0 ? { label: bpContext.allBlueprintComponents.find(sf => sf.id === options?.target_id).name } : undefined}
							defaultType={options?.selection?.length > 0 ? { label: options?.selection } : undefined}
							defaultCommon={options?.common_field?.length > 0 ? { label: options?.common_field } : undefined}
							defaultList={options?.list?.length > 0 ? options?.list : undefined}
							defaultRefList={options?.rlist?.length > 0 ? options?.rlist : undefined}
							defaultRefName={options?.referenceLabel?.length > 0 ? { label: options?.referenceLabel } : undefined}
							defaultSrcName={options?.sourceLabel?.length > 0 ? options?.sourceLabel : undefined}
							onSourceSelected={value => {
								setFieldOption({ ...fieldOption, source_id: value });
							}}
							onTargetSelected={value => {
								setFieldOption({ ...fieldOption, target_id: value });
							}}
							onFieldSelected={value => {
								setFieldOption({ ...fieldOption, common_field: value });
							}}
							onChangeList={list => {
								setFieldOption({ ...fieldOption, list: [...list] });
							}}
							onChangeRList={list => {
								setFieldOption({ ...fieldOption, rlist: [...list] });
							}}
							onTypeSelected={value => {
								setFieldOption({ ...fieldOption, selection: value });
							}}
							onSourceLabel={list => {
								setFieldOption({ ...fieldOption, sourceLabel: [...list] });
							}}
							// onSourceLabel={value => {
							// 	setFieldOption({ ...fieldOption, sourceLabel: value });
							// }}
							onReferenceLabel={value => {
								setFieldOption({ ...fieldOption, referenceLabel: value });
							}}
							onGlobalSelected={value => {
								setFieldOption({ ...fieldOption, global: value });
							}}
							onSetShowAll={value => {
								setFieldOption({ ...fieldOption, showAll: value });
							}}
							showAllDefault={options?.showAll}
							bpContext={bpContext}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'emails' && <DropdownSelectEmails validationErrors={validationErrors} options={options} setFieldOption={setFieldOption} fieldOption={fieldOption} blueprints={blueprints} />}
					{field_type?.name?.toLowerCase() === 'product lookup' && (
						<DropdownSelectProductLookup
							validationErrors={validationErrors}
							defaultTarget={bpContext.allBlueprintComponents.find(sf => sf.id === options?.component_id)}
							defaultRefName={options?.referenceLabel}
							defaultList={options?.list}
							defaultType={options?.selection?.length > 0 ? { label: options?.selection } : undefined}
							onTargetSelected={value => {
								setFieldOption({ ...fieldOption, component_id: value });
							}}
							onChangeList={list => {
								setFieldOption({ ...fieldOption, list: [...list] });
							}}
							onTypeSelected={value => {
								setFieldOption({ ...fieldOption, selection: value });
							}}
							onReferenceLabel={value => {
								setFieldOption({ ...fieldOption, referenceLabel: value });
							}}
							bpContext={bpContext}
						/>
					)}

					{field_type?.name?.toLowerCase() === 'id' && (
						<OptionSection>
							<RenderTitle title={'ID series to automatically generate'} />
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<IdGenerator
									defaultValue={options.id.length > 0 ? { label: options.id } : { label: '' }}
									onSetIdGenerator={value => {
										setFieldOption({ ...fieldOption, id: value, value: value });
									}}
								/>
							</div>
						</OptionSection>
					)}
					{field_type?.name?.toLowerCase() === 'notes' && (
						<OptionSection>
							<RenderTitle title={'Notes automatically generate'} />
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<NotesGenerator
									defaultValue={options.value.length > 0 ? { label: options.value } : { label: '' }}
									onNotesGenerator={value => {
										setFieldOption({ ...fieldOption, value: value });
									}}
								/>
							</div>
						</OptionSection>
					)}
					{field_type?.name?.toLowerCase() === 'filters' && (
						<FiltersComponent
							validationErrors={validationErrors}
							defaultTarget={bpContext.allBlueprintComponents.find(sf => sf.id === options?.component_id)}
							defaultFilters={options?.filters}
							defaultOutput={options?.output}
							defaultGlobal={options?.global}
							onGlobalSelected={value => {
								setFieldOption({ ...fieldOption, global: value });
							}}
							onTargetSelected={value => {
								setFieldOption({ ...fieldOption, component_id: value });
							}}
							onFilterList={filters => {
								setFieldOption({ ...fieldOption, filters: filters });
							}}
							onOutputSelected={value => {
								setFieldOption({ ...fieldOption, output: value });
							}}
							bpContext={bpContext}
						/>
					)}
					{field_type?.name?.toLowerCase() === 'pin' && (
						<>

							<RenderYesNoSwitcher
								{...{
									id: 'Pin with destination info?',
									title: 'Pin a second location',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.destination}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, destination: val });
								}}
							/>

							<RenderYesNoSwitcher
								{...{
									id: 'Compute distance between two pins?',
									title: `Compute distance ${fieldOption?.destination == true ? 'between two pins' : 'with current location'}`,
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.compute}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, compute: val });
								}}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Show real-time location of target?',
									title: 'Show real-time location',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.realtime}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, realtime: val });
								}}
							/>
							{fieldOption?.realtime == true && (
								<OptionSection>
									<RenderTitle title={'Interval'} />
									<UnfocusableWrapper className={cls.unfocusableWrapper}>
										<input
											type={'number'}
											className={cls.dropDownNewListItemInput}
											value={intervalVal}
											name={'Interval'}
											onChange={e => {
												setIntervalVal(e.target.value);
												setFieldOption({ ...fieldOption, interval: +e.target.value });
											}}
										/>
									</UnfocusableWrapper>
								</OptionSection>
							)}
						</>
					)}

					{field_type?.name?.toLowerCase() === 'ratings' && (
						<>
							<OptionSection>
								<RenderTitle title={'Field to lookup user ID'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldlVal}
										name={'Field to lookup user ID'}
										onChange={e => {
											setFieldVal(e.target.value);
											setFieldOption({ ...fieldOption, field: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<RenderYesNoSwitcher
								{...{
									id: 'Add comments field?',
									title: 'Add comments field?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.comments}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, comments: val });
								}}
							/>

							<RenderYesNoSwitcher
								{...{
									id: 'Allow users to view rating?',
									title: 'Allow users to view rating?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.view}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, view: val });
								}}
							/>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'rewards' && (
						<>


							{/* status: 'Closed',
							points: 1,
							equiv: 1,
							target: 'creator', */}

							<OptionSection>
								<RenderTitle title={'Apply rewards when status matches'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.status ?? 'Closed'}
										name={'Status to compare'}
										onChange={e => {
											//setFieldVal(e.target.value);
											setFieldOption({ ...fieldOption, status: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Reward points to apply'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'number'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.points ?? 1}
										name={'Reward points to apply'}
										onChange={e => {
											//setFieldVal(e.target.value);
											setFieldOption({ ...fieldOption, points: +e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Equivalent wallet value to apply'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'number'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.equiv ?? 1}
										name={'Equivalent wallet value to apply'}
										onChange={e => {
											setFieldOption({ ...fieldOption, equiv: +e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<RenderYesNoSwitcher
								{...{
									id: 'Apply rewards to transaction creator',
									title: 'Apply rewards to transaction creator?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={fieldOption?.creator ?? true}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, creator: val });
								}}
							/>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'link' && (
						<>
							<RenderYesNoSwitcher
								{...{
									id: 'Display as image',
									title: 'Show link as an image?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={fieldOption?.image ?? false}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, image: val });
								}}
							/>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'video' && (
						<>
							<OptionSection>
								<RenderTitle title={'Status to match'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.status ?? 'Accepted'}
										onChange={e => {
											setFieldOption({ ...fieldOption, status: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Time that join button is available (mins)'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'number'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.expiry ?? 10}
										onChange={e => {
											setFieldOption({ ...fieldOption, expiry: +e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'calendar' && (
						<>
							<OptionSection>
								<RenderTitle title={'Header label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.header}
										onChange={e => {
											setFieldOption({ ...fieldOption, header: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Title label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.title ?? 'Event Name'}
										//name={'Equivalent wallet value to apply'}
										onChange={e => {
											setFieldOption({ ...fieldOption, title: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Description label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.description ?? 'Description'}

										onChange={e => {
											setFieldOption({ ...fieldOption, description: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Assignee label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.assignee ?? 'Assigned to'}
										//name={'Equivalent wallet value to apply'}
										onChange={e => {
											setFieldOption({ ...fieldOption, assignee: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Start date label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.start ?? 'Start Date'}
										//name={'Equivalent wallet value to apply'}
										onChange={e => {
											setFieldOption({ ...fieldOption, start: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'End date label to use'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.end ?? 'End Date'}
										//name={'Equivalent wallet value to apply'}
										onChange={e => {
											setFieldOption({ ...fieldOption, end: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<OptionSection>
								<RenderTitle title={'Value label to use (Optional)'} />
								<UnfocusableWrapper className={cls.unfocusableWrapper}>
									<input
										type={'text'}
										className={cls.dropDownNewListItemInput}
										value={fieldOption?.valueL}
										onChange={e => {
											setFieldOption({ ...fieldOption, valueL: e.target.value });
										}}
									/>
								</UnfocusableWrapper>
							</OptionSection>
							<StatusSelect
								validationErrors={validationErrors}
								defaultList={options?.list?.length > 0 ? options?.list : undefined}
								onListSelected={list => {
									setFieldOption({ ...fieldOption, list: list });
								}}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Retrieve calendar of transaction creator',
									title: 'Retrieve calendar of transaction creator only?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.creator}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, creator: val });
								}}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Limit calendar to module component attached',
									title: 'Limit calendar to module it is attached to?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.limited}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, limited: val });
								}}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Allow event to span multiple dates',
									title: 'Allow event to span multiple dates?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.span}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, span: val });
								}}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Show calendar priority',
									title: 'Show calendar priority?',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.priority}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, priority: val });
								}}
							/>
						</>
					)}
					{field_type?.name?.toLowerCase() === 'info' && (
						<>
							<InfoSelect
								defaultOrientation={options?.orientation ?? 'Rows'}
								defaultKey={options?.key ?? 'Product ID'}
								onKeySelected={selection => {
									setFieldOption({ ...fieldOption, key: selection });
								}}
								blueprints={blueprints}
								validationErrors={validationErrors}
								defaultList={options?.list?.length > 0 ? options?.list : undefined}
								defaultType={blueprints
									.map(elem => {
										return { label: elem.name, value: elem.id, module: elem };
									})
									.find(i => i?.value === (options?.component_id ?? ''))}
								onTypeSelected={selection => {
									setFieldOption({ ...fieldOption, component_id: selection.component.id });
								}}
								onChangeList={list => {
									setFieldOption({ ...fieldOption, list: [...list] });
								}}
								onChangeOrientation={selection => {
									setFieldOption({ ...fieldOption, orientation: selection });
								}}
								multiSelectLabel={
									options?.component_id === ''
										? ''
										: blueprints
											.map(elem => {
												return { label: elem.name, value: elem.id, module: elem };
											})
											.find(i => i?.value === (options?.component_id ?? '')).label
								}
							/>
							<RenderYesNoSwitcher
								{...{
									id: 'Email Info',
									title: 'Show this info in emails',
									ungrouped: true,
									withAckButton: false,
								}}
								style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
								value={options?.email}
								toggleSelected={val => {
									setFieldOption({ ...fieldOption, email: val });
								}}
							/>
						</>
					)}

					{field_type?.name?.toLowerCase() === 'chart' && (
						<>
							<ChartSelect
								defaultOrientation={options?.orientation ?? 'Rows'}
								defaultKey={options?.key ?? ''}
								onKeySelected={selection => {
									setFieldOption({ ...fieldOption, key: selection });
								}}
								blueprints={blueprints}
								validationErrors={validationErrors}
								defaultList={options?.list?.length > 0 ? options?.list : undefined}
								defaultType={blueprints
									.map(elem => {
										return { label: elem.name, value: elem.id, module: elem };
									})
									.find(i => i?.value === (options?.component_id ?? ''))}
								onTypeSelected={selection => {
									setFieldOption({ ...fieldOption, component_id: selection.component.id });
								}}
								onChangeList={list => {
									setFieldOption({ ...fieldOption, list: [...list] });
								}}
								onChangeOrientation={selection => {
									setFieldOption({ ...fieldOption, orientation: selection });
								}}
								multiSelectLabel={
									options?.component_id === ''
										? ''
										: blueprints
											.map(elem => {
												return { label: elem.name, value: elem.id, module: elem };
											})
											.find(i => i?.value === (options?.component_id ?? '')).label
								}
							/>
						</>
					)}

				</div>

				{field_type?.name?.toLowerCase() !== 'emails' && (
					<OptionsSettings
						selectedFields={selectedFields}
						options={options}
						fieldType={field_type?.name}
						onOptionsSelected={value => {
							setFieldOption(value);
						}}
						fieldOption={fieldOption}
					/>
				)}
				<div className={cls.controls}>
					<Button title={'Close'} clicked={onClose}></Button>
					{!readOnly && <Button light title="Save" loading={centralControlLoading} clicked={onSavePressed} />}
					{!readOnly && leftControlAction && <Button title="Delete" light loading={leftControlLoading} clicked={() => leftControlAction(id)} />}
				</div>
			</div>
		</ModalR>
	);
};
export const TransactionSelectList = props => {
	const { setFieldOption, options, defaultType, defaultModule, defaultMap, onModuleSelected, onTypeSelected, defaultList, validationErrors, allModules, onChangeMapping, multiSelectLabel = 'Default', companyProcesses, blueprints } = props;
	const [selectedModule, setSelectedModule] = useState(undefined);
	const [list,] = useState(defaultMap ?? {});
	const [cleared, setCleared] = useState(false);
	const [multiselectItems, setMultiselectItems] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, blueprint_name: defaultModule?.label ?? 'Others' };
				})
	);

	useEffect(() => {
		//getProcess(defaultModule?.module?.procedure?.id);
		getAllBlueprintFields();
	}, []);

	const getProcess = id => {
		if (companyProcesses.length > 0 && id !== undefined) {
			let items = [];
			companyProcesses
				.find(proc => proc.id === id)
				?.steps?.filter(step => {
					step.component_blueprints.filter(bpc => {
						bpc.selected_fields
							.filter(sf => sf.visible == true)
							.filter(field => {
								items = [...items, field.field_name];
							});
					});
				});
			items = [...new Set(items)].map(it => {
				return { label: it, value: it, blueprint_name: defaultModule?.label ?? 'Others' };
			});
			setMultiselectItems([...R.sortWith([R.ascend(R.prop('label'))])(items)]);
		}
	};

	const getAllBlueprintFields = () => {
		if (blueprints.length > 0) {
			let items = [];
			blueprints.filter(bp => {
				bp.fields.filter(bpf => {
					items = [...items, bpf.value];
				});
			});

			items = [...new Set(items)].map(it => {
				return { label: it, value: it, blueprint_name: it };
			});
			setMultiselectItems([...R.sortWith([R.ascend(R.prop('label'))])(items)]);
		}
	};
	const modulesArray = allModules.map(elem => {
		return { label: elem.name, value: elem.id, module: elem };
	});

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Module to list'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={modulesArray}
						defaultSelected={defaultModule}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							getProcess(selection.module.procedure.id);
							setSelectedModule(selection);
							onModuleSelected?.(selection);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to map with Item Code'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={multiselectItems}
						defaultSelectedItem={defaultMap?.item_code ? { label: defaultMap.item_code, value: defaultMap.item_code } : null}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							list.item_code = selection.value;
							onChangeMapping?.(list);
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to map with Quantity'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={multiselectItems}
						defaultSelectedItem={defaultMap?.quantity ? { label: defaultMap.quantity, value: defaultMap.quantity } : null}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							list.quantity = selection.value;
							onChangeMapping?.(list);
						}}
					/>
				</div>
			</OptionSection>


			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to map with Value'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={multiselectItems}
						defaultSelectedItem={defaultMap?.value ? { label: defaultMap.value, value: defaultMap.value } : null}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							list.value = selection.value;
							onChangeMapping?.(list);
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to map with Alt Value'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={multiselectItems}
						defaultSelectedItem={defaultMap?.alt_value ? { label: defaultMap.alt_value, value: defaultMap.alt_value } : null}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							list.alt_value = selection.value;
							onChangeMapping?.(list);
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to map with Total'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={multiselectItems}
						defaultSelectedItem={defaultMap?.total ? { label: defaultMap.total, value: defaultMap.total } : null}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							list.total = selection.value;
							onChangeMapping?.(list);
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'List type'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={[
							{ label: 'Table', value: 'Table' },
							{ label: 'List (max 4 fields displayed)', value: 'List' },
						]}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							onTypeSelected?.(selection);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<RenderYesNoSwitcher
				{...{
					id: 'Show Footer',
					title: 'Show footer?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={options?.footer}
				toggleSelected={val => {
					setFieldOption({ ...options, footer: val });
				}}
			/>
		</div>
	);
};

export const OptionsSettings = props => {
	const { fieldType, selectedFields, fieldOption, errorMessage, noBorder, options, onOptionsSelected } = props;

	const [addOptions, setAddOptions] = useState(((options?.valueToMatch || options?.valueToMatch2) && options?.fieldToMatch) || options?.mapToItem || options?.notes || options?.width);
	const [valueA, setValueA] = useState(options?.valueToMatch);
	const [valueB, setValueB] = useState(options?.valueToMatch2);
	const [fieldToSet, setFieldToSet] = useState(options?.fieldToMatch);
	const [noteToSet, setNoteToSet] = useState(options?.notes);

	const initialStateArray = [
		{ label: 'Make field visible and mandatory', value: 'visman' },
		{ label: 'Make field visible', value: 'visible' },
		{ label: 'Make field hidden', value: 'hidden' },
		{ label: 'Make field mandatory', value: 'mandatory' },
		{ label: 'Make field optional', value: 'optional' },
		{ label: 'Disable the field', value: 'disable' },
		{ label: 'Enable the field', value: 'enable' },
	];
	const actionsArray = [
		{ label: 'Make field visible and mandatory', value: 'visman' },
		{ label: 'Show the field', value: 'visible' },
		{ label: 'Hide the field', value: 'hide' },
		{ label: 'Make field mandatory', value: 'mandatory' },
		{ label: 'Make field optional', value: 'optional' },
		{ label: 'Disable the field', value: 'disable' },
		{ label: 'Enable the field', value: 'enable' },
		{ label: 'Trigger an email during save', value: 'email' },
	];

	const valuesArray = [
		{ label: 'Set date today', value: 'today', type: 'Date' },
		{ label: 'Set date tomorrow', value: 'tomorrow', type: 'Date' },
		{ label: 'Set date one month from today', value: 'month', type: 'Date' },
		{ label: 'Set date one week from today', value: 'week', type: 'Date' },
		{ label: 'None', value: 'none', type: 'Date' },
	];
	const fieldsArray = selectedFields.map(field => {
		return { label: field.value, value: field.value, field: field };
	});

	return (
		<div className={cls.optionSettings} app-container-variant={noBorder ? 'noBorder' : 'border'} app-variant={errorMessage?.length > 0 ? 'error' : 'normal'}>
			{!(fieldType === 'GoogleAI Assistant' || fieldType === 'OpenAI Assistant') && (
				<RenderYesNoSwitcher
					{...{
						id: 'Show additional options?',
						title: 'Show additional options?',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
					value={addOptions ? true : false}
					toggleSelected={val => {
						setAddOptions(val);
					}}
				/>
			)}
			{addOptions && (
				<div>
					<OptionSection>
						<RenderTitle title={'Add Notes'} />
						<UnfocusableWrapper className={cls.unfocusableWrapper}>
							<textarea
								type={'text'}
								className={cls.dropDownNewListItemInput}
								value={noteToSet}
								name={'matched to field'}
								onChange={e => {
									setNoteToSet(e.target.value);
									onOptionsSelected({ ...fieldOption, notes: e.target.value });
								}}
							/>
						</UnfocusableWrapper>
					</OptionSection>
				</div>
			)}
			{addOptions && (
				<OptionSection>
					<RenderTitle title={'Field name to match values'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={fieldToSet}
							name={'matched to field'}
							onChange={e => {
								setFieldToSet(e.target.value);
								onOptionsSelected({ ...fieldOption, fieldToMatch: e.target.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && fieldToSet?.length > 3 && (
				<OptionSection>
					<RenderTitle title={"Values (A) to be matched to field. (Note: Use ' , ' to seperate)"} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={valueA}
							name={'matched to field'}
							onChange={e => {
								setValueA(e.target.value);
								onOptionsSelected({ ...fieldOption, valueToMatch: e.target.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{addOptions && valueA?.length > 3 && (
				<OptionSection>
					<RenderTitle title={'Perform this action when matched with (A)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={actionsArray.filter(act => act.value !== 'email')}
							defaultSelectedItem={actionsArray.find(action => action.value === options?.actionToPerform)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOptionsSelected({ ...fieldOption, actionToPerform: selection.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && valueA?.length > 3 && (
				<OptionSection>
					<RenderTitle title={'Perform this action when not matched with (A)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={initialStateArray}
							defaultSelectedItem={initialStateArray.find(state => state.value === options?.initialState)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOptionsSelected({ ...fieldOption, initialState: selection.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && fieldToSet?.length > 3 && (
				<OptionSection>
					<RenderTitle title={"Values (B) to be matched to field. (Note: Use ' , ' to seperate)"} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={valueB}
							name={'matched to field'}
							onChange={e => {
								setValueB(e.target.value);
								onOptionsSelected({ ...fieldOption, valueToMatch2: e.target.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && valueB?.length > 3 && fieldType === 'Date' && (
				<OptionSection>
					<RenderTitle title={'Change to this value when matched with (B)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={valuesArray}
							defaultSelectedItem={valuesArray.filter(val => val.type === fieldType).find(val => val.value === options?.value)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOptionsSelected({ ...fieldOption, value: selection.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && valueB?.length > 3 && (fieldType === 'Text' || fieldType === 'Status') && (
				<OptionSection>
					<RenderTitle title={'Change to this value when matched'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={fieldType.toLowerCase()}
							className={cls.dropDownNewListItemInput}
							placeholder={options?.value}
							name={'matched to field'}
							onChange={e => {
								onOptionsSelected({ ...fieldOption, value: e.target.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{addOptions && valueB?.length > 3 && (
				<OptionSection>
					<RenderTitle title={'Perform action to field when matched with (B)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={options?.actionToPerform ? actionsArray.filter(act => !(act.value === options?.actionToPerform || act.value === options?.initialState)) : actionsArray}
							defaultSelectedItem={actionsArray.find(action => action.value === options?.actionToPerform2)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOptionsSelected({ ...fieldOption, actionToPerform2: selection.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{addOptions && (
				<OptionSection>
					<RenderTitle title={'Map this field to item field'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={[
								{ label: 'Item Code', value: 'item_code' },
								{ label: 'Quantity', value: 'quantity' },
								{ label: 'Value', value: 'value' },
								{ label: 'Other Value', value: 'alt_value' },
								{ label: 'Total', value: 'total' },
								{ label: 'Distance', value: 'distance' },
							]}
							defaultSelectedItem={[
								{ label: 'Item Code', value: 'item_code' },
								{ label: 'Quantity', value: 'quantity' },
								{ label: 'Value', value: 'value' },
								{ label: 'Other Value', value: 'alt_value' },
								{ label: 'Total', value: 'total' },
								{ label: 'Distance', value: 'distance' },
							].find(val => val.value === options?.mapToItem)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOptionsSelected({ ...fieldOption, mapToItem: selection.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{addOptions && (
				<OptionSection>
					<br />
					<RenderTitle title={'Field width (min 15%, will fit 4 fields in row)'} />

					<div style={{ display: 'flex', width: '100%' }}>
						<input type={'range'} min='15' max='100' step='5'
							style={{ width: '90%' }}
							defaultValue={(options?.width ?? '0')}
							onChange={e => {
								onOptionsSelected({ ...fieldOption, width: e.target.value });
							}}

						/>
						<label style={{ width: '10%' }}>
							{fieldOption?.width ?? options?.width}%
						</label>
					</div>


					<br />

				</OptionSection>
			)}
			{addOptions && (
				<RenderYesNoSwitcher
					{...{
						id: 'Do not save this value?',
						title: 'Do not save this value?',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
					value={options?.skip}
					toggleSelected={val => {
						onOptionsSelected({ ...fieldOption, skip: val });
					}}
				/>
			)}
			{addOptions && (
				<RenderYesNoSwitcher
					{...{
						id: 'Read only?',
						title: 'Read only?',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
					value={options?.read}
					toggleSelected={val => {
						onOptionsSelected({ ...fieldOption, read: val });
					}}
				/>
			)}
		</div>
	);
};

export const OptionSection = props => {
	const { children, errorMessage, noBorder } = props;
	return (
		<div className={cls.optionSection} app-container-variant={noBorder ? 'noBorder' : 'border'} app-variant={errorMessage?.length > 0 ? 'error' : 'normal'}>
			{children}
			{errorMessage?.length > 0 && <span className={cls.errorContainer}>{errorMessage}</span>}
		</div>
	);
};
export const NumberOptions = props => {
	const { defaultValue, onChangeType, validationErrors } = props;
	const [selected, setSelected] = useState(defaultValue ?? false);
	return (
		<OptionSection errorMessage={validationErrors?.type}>
			<RenderTitle title={'Type of Element'} />
			<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} app-container-variant={'noBorder'} className={cls.numberOptionsContainer}>
				<RenderBooleansOptions
					selected={selected}
					setSelected={val => {
						setSelected(val);
						onChangeType(val ? 'numeric' : 'percentage');
					}}
					first={'123 Value'}
					second={'% Percentage'}
					extraClassName={cls.renderBooleansOptions}
				/>
			</UnfocusableWrapper>
		</OptionSection>
	);
};
export const DateOptions = props => {
	const { defaultValue, defaultAuto, onChangeType, onExceeded, validationErrors } = props;
	const [selected, setSelected] = useState(defaultValue ?? false);
	return (
		<OptionSection errorMessage={validationErrors?.type}>
			<RenderTitle title={'Type of Element'} />
			<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} app-container-variant={'noBorder'} className={cls.dateOptionsContainer}>
				<RenderBooleansOptions
					selected={selected}
					setSelected={val => {
						setSelected(val);
						onChangeType(val ? 'single' : 'range');
					}}
					first={'Single date'}
					second={'Date Range'}
					extraClassName={cls.renderBooleansOptions}
				/>
			</UnfocusableWrapper>
			<RenderYesNoSwitcher
				{...{
					id: 'Auto-close when date is exceeded',
					title: 'Auto-close when date is exceeded?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultAuto}
				toggleSelected={val => {
					onExceeded(val);
				}}
			/>
		</OptionSection>
	);
};
export const URLOptions = props => {
	const { defaultValue, defaultType, onChangeType, onChangeValue, validationErrors } = props;
	const [selected, setSelected] = useState(defaultType ?? false);
	const [textV, setTextV] = useState(defaultValue);
	return (
		<OptionSection errorMessage={validationErrors?.type}>
			<RenderTitle title={'URL Link'} />
			<input
				type="text"
				name="element-name"
				value={textV}
				onChange={e => {
					onChangeValue(e.target.value);
					setTextV(e.target.value);
				}}
				className={cls.elementTextInput}
			/>

			<RenderTitle title={'Type of Element'} />
			<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} app-container-variant={'noBorder'} className={cls.numberOptionsContainer}>
				<RenderBooleansOptions
					selected={selected}
					setSelected={val => {
						setSelected(val);
						onChangeType(val ? 'image' : 'link');
					}}
					first={'Image'}
					second={'Link'}
					extraClassName={cls.renderBooleansOptions}
				/>
			</UnfocusableWrapper>
		</OptionSection>
	);
};
export const CheckboxOptions = props => {
	const { defaultValue, onChangeValue, validationErrors } = props;
	const [textV, setTextV] = useState(defaultValue);
	return (
		<OptionSection errorMessage={validationErrors?.type}>
			<RenderTitle title={'Label'} />
			<input
				type="text"
				name="element-name"
				value={textV}
				onChange={e => {
					onChangeValue(e.target.value);
					setTextV(e.target.value);
				}}
				className={cls.elementTextInput}
			/>
		</OptionSection>
	);
};
export const PhonePrefix = props => {
	const { onChange, countryCode, defaultValue, validationErrors } = props;
	const [stringValue, setStringValue] = useState(defaultValue);
	return (
		<OptionSection errorMessage={validationErrors?.country_prefix}>
			<RenderTitle title={'Phone Number Prefix'} />
			<UnfocusableWrapper
				app-variant={validationErrors?.country_prefix ? 'error' : 'normal'}
				className={cls.unfocusableWrapper}
				onClickOutside={() => { }}
				onClick={() => {
					const button = document.getElementsByClassName('selected-flag');
					if (button[0]) {
						button[0]?.click();
					}
				}}
			>
				<PhoneInput
					specialLabel={''}
					enableSearch
					country={countryCode ?? 'ph'}
					onChange={(_changeValue, data, _event) => {
						const stringValue = '+' + data.dialCode + ' ' + data.name;
						setStringValue(stringValue);
						onChange?.({ country_prefix: `+${data?.dialCode}`, country_code: data?.countryCode, country_name: data?.name });
					}}
					value={stringValue}
					inputProps={{ name: 'phone', required: false, autoFocus: false }}
					countryCodeEditable={false}
					containerClass={classNames(cls.phoneIContainer, cls.borderClass)}
					inputClass={cls.phoneIInput}
					buttonClass={cls.phoneIButton}
					dropdownClass={cls.phoneIDropdown}
					searchClass={cls.phoneISearch}
				/>
				<p className={cls.phonePrefixText}>{stringValue ?? '+44 United Kingdom'}</p>
			</UnfocusableWrapper>
		</OptionSection>
	);
};
export const CurencySelect = props => {
	const { defaultValue, onCurrencySelected, validationErrors } = props;
	const [selected, setSelected] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	return (
		<OptionSection errorMessage={validationErrors?.currency_type}>
			<RenderTitle title={'Currency Type'} />
			<UnfocusableWrapper app-variant={validationErrors?.currency_type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
				<pre className={cls.currencySymbol}>{selected?.symbol ? selected?.symbol : cleared ? '' : defaultValue?.symbol} </pre>
				<SelectWithSearch
					items={currency_list}
					defaultSelectedItem={defaultValue}
					triggerType={'chevron'}
					className={cls.currencySelectComponent}
					inputClassName={cls.currencySearchInput}
					clearControlsClassName={cls.currencyClearControlsClassName}
					molclassname={cls.currencySelectMenuOverlayList}
					moliclassname={cls.currencySelectMenuOverlayListItem}
					inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
					onChange={selection => {
						setSelected(selection);
						onCurrencySelected(selection);
						if (selection?.symbol) {
							setCleared(false);
						} else {
							setCleared(true);
						}
					}}
				/>
			</UnfocusableWrapper>
		</OptionSection>
	);
};
export const ActionSelect = props => {
	const { defaultValue, onActionSelected, validationErrors } = props;
	const [selected, setSelected] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	return (
		<OptionSection errorMessage={validationErrors?.currency_type}>
			<RenderTitle title={'Action Type'} />
			<UnfocusableWrapper app-variant={validationErrors?.currency_type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
				<SelectWithSearch
					items={actionsArray}
					defaultSelectedItem={actionsArray.find(action => action.value === defaultValue)}
					triggerType={'chevron'}
					className={cls.currencySelectComponent}
					inputClassName={cls.currencySearchInput}
					clearControlsClassName={cls.currencyClearControlsClassName}
					molclassname={cls.currencySelectMenuOverlayList}
					moliclassname={cls.currencySelectMenuOverlayListItem}
					inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
					onChange={selection => {
						setSelected(selection);
						onActionSelected(selection);
						if (selection?.symbol) {
							setCleared(false);
						} else {
							setCleared(true);
						}
					}}
				/>
			</UnfocusableWrapper>
			<br />
			<RenderTitle title={'Notes: The Action Button is only shown in the detail popup screen'} />
		</OptionSection>
	);
};

export const ProfileSelect = props => {
	const { defaultValue, defaultOption, onProfileSelected, validationErrors } = props;
	const [selected, setSelected] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const profileArray = [
		{ label: 'User that made the transaction', value: 'creator' },
		{ label: 'Wallet of user that made the transaction', value: 'wallet' },
		{ label: 'User that accepted the transaction', value: 'owner' },
		{ label: 'Custom', value: 'custom' },
	];
	return (
		<OptionSection errorMessage={validationErrors?.currency_type}>
			<RenderTitle title={'Show profile of user'} />
			<UnfocusableWrapper app-variant={validationErrors?.currency_type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
				<SelectWithSearch
					items={profileArray}
					defaultSelectedItem={profileArray.find(action => action.value === defaultValue)}
					triggerType={'chevron'}
					className={cls.currencySelectComponent}
					inputClassName={cls.currencySearchInput}
					clearControlsClassName={cls.currencyClearControlsClassName}
					molclassname={cls.currencySelectMenuOverlayList}
					moliclassname={cls.currencySelectMenuOverlayListItem}
					inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
					onChange={selection => {
						setSelected(selection);
						onProfileSelected(selection);
						if (selection?.symbol) {
							setCleared(false);
						} else {
							setCleared(true);
						}
					}}
				/>
			</UnfocusableWrapper>
			<RenderYesNoSwitcher
				{...{
					id: 'Show profile picture',
					title: 'Show profile picture?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultOption?.show}
				toggleSelected={val => {
					defaultOption.show = val;
				}}
			/>
		</OptionSection>
	);
};
export const DropdownSelect = props => {
	const { defaultPr, defaultJ1, defaultJ2, defaultType, onTypeSelected, defaultList, onListSelected, validationErrors, onJump1Selected = () => { }, onJump2Selected = () => { }, companyProcesses, onProcSelected = () => { } } = props;
	const [selectedType, setSelectedType] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [list, setList] = useState(defaultList ?? []);
	const [showAddOption, setShowAddOption] = useState(true);
	const [newItem, setNewItem] = useState('');
	const [jump1, setJump1] = useState(defaultJ1);
	const [jump2, setJump2] = useState(defaultJ2);
	const [proc, setProc] = useState(defaultPr);

	const onAddNewListItem = useCallback(() => {
		setShowAddOption(true);
		setList(c => [...c, newItem]);
		setNewItem('');
		onListSelected?.([...list, newItem]);
	}, [list, newItem, onListSelected]);

	const onRemoveListItem = useCallback(
		item => {
			setList(c => [...R.reject(R.equals(item), c)]);
			onListSelected?.([...R.reject(R.equals(item), list)]);
		},
		[list, onListSelected]
	);
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Dropdown Type'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<pre className={cls.dropdownSymbol}>{selectedType?.icon ? selectedType?.icon : cleared ? '' : defaultType?.icon} </pre>
					<SelectD
						items={dropdownComponentSelectionType}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							setSelectedType(selection);
							onTypeSelected?.(selection ?? 'single');
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.list}>
				<RenderTitle title={'Dropdown Options'} />
				<UnfocusableWrapper
					app-variant={validationErrors?.list ? 'error' : 'normal'}
					className={cls.dropdownListContainer}
					onClickOutside={useCallback(() => {
						onListSelected?.(list);
					}, [list, onListSelected])}
				>
					{showAddOption ? (
						<div className={cls.dropDownButtonContainer}>
							<Button light title={'Add Dropdown Option'} clicked={() => setShowAddOption(false)} />
						</div>
					) : (
						<div className={cls.dropDownNewListItemInputContainer}>
							<input
								type={'text'}
								className={cls.dropDownNewListItemInput}
								value={newItem}
								name={'newItem'}
								placeholder={'Type name'}
								onChange={e => {
									setNewItem(e.target.value);
								}}
							/>
							{newItem?.length > 0 && (
								<button type={'button'} onClick={onAddNewListItem} className={cls.dropdownInputButtonSaveNewItem}>
									<FontAwesomeIcon icon={faSquarePlus} />
								</button>
							)}
						</div>
					)}
					<div className={cls.dropdownListItemsContainer}>
						{list &&
							list?.map((item, idx) => (
								<RenderEditableListItem
									key={item + idx}
									item={item}
									isOverflow={list?.length > 3}
									onItemChanged={newItem => {
										setList(c => [...R.update(idx)(newItem)(c)]);
									}}
									onItemRemoved={() => onRemoveListItem(item)}
								/>
							))}
					</div>
				</UnfocusableWrapper>
			</OptionSection>


			{(jump1 || selectedType?.value === 'decision') &&
				<OptionSection errorMessage={validationErrors?.position_id}>
					<RenderTitle title={'Process where to look for step'} />
					<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
						<SelectD
							items={
								companyProcesses.map(pro => {
									return { label: pro.name, value: pro.id, process: pro }
								})
							}
							defaultSelected={{ label: companyProcesses.find(pro => pro.id === proc)?.name }}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								setProc(selection.value);
								onProcSelected?.(selection.value);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>}

			{(jump1 || selectedType?.value === 'decision') && proc && <OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'Dropdown option 1 jumps to step'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<SelectD
						items={
							companyProcesses.find(pro => pro.id === proc)?.steps?.map(step => {
								return { label: step.name, value: step.id, step: step }
							})
						}
						defaultSelected={{ label: companyProcesses.find(pro => pro.id === proc)?.steps?.find(step => step.id === jump1)?.name }}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							setJump1(selection.value);
							onJump1Selected?.(selection.value);
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>}

			{(jump2 || selectedType?.value === 'decision') && proc && <OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'Dropdown option 2 jumps to step'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<SelectD
						items={
							companyProcesses.find(pro => pro.id === proc)?.steps.map(step => {
								return { label: step.name, value: step.id, step: step }
							})
						}
						defaultSelected={{ label: companyProcesses.find(pro => pro.id === proc)?.steps?.find(step => step.id === jump2)?.name }}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							setJump2(selection.value);
							onJump2Selected?.(selection.value);
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>}



		</div>
	);
};

export const StatusSelect = props => {
	const { defaultList, onListSelected, validationErrors, title = 'Dropdown Options' } = props;
	const [list, setList] = useState(defaultList ?? []);
	const [showAddOption, setShowAddOption] = useState(true);
	const [newItem, setNewItem] = useState('');
	const onAddNewListItem = useCallback(() => {
		setShowAddOption(true);
		setList(c => [...c, newItem]);
		setNewItem('');
		onListSelected?.([...list, newItem]);
	}, [list, newItem, onListSelected]);

	const onRemoveListItem = useCallback(
		item => {
			setList(c => [...R.reject(R.equals(item), c)]);
			onListSelected?.([...R.reject(R.equals(item), list)]);
		},
		[list, onListSelected]
	);
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.list}>
				<RenderTitle title={title} />
				<UnfocusableWrapper
					app-variant={validationErrors?.list ? 'error' : 'normal'}
					className={cls.dropdownListContainer}
					onClickOutside={useCallback(() => {
						onListSelected?.(list);
					}, [list, onListSelected])}
				>
					{showAddOption ? (
						<div className={cls.dropDownButtonContainer}>
							<Button light title={'Add Options'} clicked={() => setShowAddOption(false)} />
						</div>
					) : (
						<div className={cls.dropDownNewListItemInputContainer}>
							<input
								type={'text'}
								className={cls.dropDownNewListItemInput}
								value={newItem}
								name={'newItem'}
								placeholder={'Type name'}
								onChange={e => {
									setNewItem(e.target.value);
								}}
							/>
							{newItem?.length > 0 && (
								<button type={'button'} onClick={onAddNewListItem} className={cls.dropdownInputButtonSaveNewItem}>
									<FontAwesomeIcon icon={faSquarePlus} />
								</button>
							)}
						</div>
					)}
					<div className={cls.dropdownListItemsContainer}>
						{list &&
							list?.map((item, idx) => (
								<RenderEditableListItem
									key={item + idx}
									item={item}
									isOverflow={list?.length > 3}
									onItemChanged={newItem => {
										setList(c => [...R.update(idx)(newItem)(c)]);
									}}
									onItemRemoved={() => onRemoveListItem(item)}
								/>
							))}
					</div>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};
export const DropdownSelectProducts = props => {
	const { defaultList, validationErrors, onChangeList, products } = props;

	useEffect(() => {
		getProducts(1).pipe(first()).subscribe({});
	}, []);

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What products to display?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<MultiselectWithSearchR
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						tagType={'shadowed' /* | "step-plated" | "plated" */}
						tagEmplacement={'inlined'}
						items={products.map(prod => {
							return { label: prod.name, value: prod.product_code };
						})}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						//initialSelectedItems={fileExtensions?.filter(i => defaultList?.indexOf(i?.value?.replaceAll('.', '')) > -1)}
						initialSelectedItems={
							defaultList === undefined
								? []
								: defaultList.length === 0
									? []
									: defaultList.map(d => {
										const product = products.find(prod => prod.product_code === d);
										return { label: product?.name, value: product?.product_code };
									})
						}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						//placeholder={'Select products...'}
						onChange={selection => {
							onChangeList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};
export const DropdownSelectList = props => {
	const { defaultType, defaultModule, onModuleSelected, onTypeSelected, defaultList, validationErrors, allModules, onChangeList, multiSelectLabel = 'Default' } = props;
	const [selectedModule, setSelectedModule] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [, setLoadingComponents] = useState(false);
	const [multiselectItems, setMultiselectItems] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, blueprint_name: multiSelectLabel };
				})
	);

	const getProcess = id => {
		getProcessById(id)
			.pipe(first())
			.subscribe({
				next: data => {
					let items = [];
					data.steps?.filter(step => {
						step.component_blueprints.filter(bpc => {
							bpc.selected_fields.filter(field => {
								items = [
									...items,
									{
										label: field.field_name,
										value: field.field_name,
										blueprint_name: bpc.name,
										blueprint_id: bpc.id,
									},
								];
							});
						});
					});

					setMultiselectItems([
						...R.sortWith([R.ascend(R.prop('blueprint_name'))])(items),
						{
							label: 'Created Date',
							value: 'Created Date',
							blueprint_name: 'Others',
						},
						{
							label: 'Total Qty',
							value: 'Total Qty',
							blueprint_name: 'Others',
						},
					]);
					setLoadingComponents(false);
				},
				error: _error => {
					// setLoadingInitialPos(false);
				},
			});
	};

	const modulesArray = allModules.map(elem => {
		return { label: elem.name, value: elem.id, module: elem };
	});

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Module to list'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<pre className={cls.dropdownSymbol}>{selectedModule?.icon ? selectedModule?.icon : cleared ? '' : defaultModule?.icon} </pre>
					<SelectD
						items={modulesArray}
						defaultSelected={defaultModule}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							getProcess(selection.module.procedure.id);
							setSelectedModule(selection);
							onModuleSelected?.(selection);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What fields to display?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<MultiSelectWithCheckboxesAndNestedItems_v2
						items={multiselectItems}
						groupedBy={'blueprint_name'}
						groupname={'blueprint_name'}
						triggerType={'chevron'}
						initialSelectedValues={
							defaultList === undefined
								? []
								: defaultList.length === 0
									? []
									: defaultList.map(d => {
										return { label: d, value: d, blueprint_name: multiSelectLabel };
									})
						}
						className={cls.peoplePositionsSelect}
						inputClassName={cls.peoplePositionsSelectInput}
						molclassname={cls.peoplePositionsSelectMenuOverlay}
						moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
						onChange={selection => {
							onChangeList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'List type'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={[
							{ label: 'Table', value: 'Table' },
							{ label: 'List (max 4 fields displayed)', value: 'List' },
						]}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							onTypeSelected?.(selection);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};

export const TextSelect = props => {
	const { defaultKey, defaultType, defaultList, onKeySelected, onTypeSelected, validationErrors, blueprints, onChangeList, multiSelectLabel = 'Default', allModules, components } = props;
	const [selectedType, setSelectedType] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [, setLoadingComponents] = useState(true);
	const [modules] = useState(
		allModules.map(elem => {
			return { label: elem.name, value: elem.id, module: elem };
		})
	);
	const [multiselectItems, setMultiselectItems] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, blueprint_name: multiSelectLabel };
				})
	);
	const operatorArray = [
		{ label: 'Sum of the fields (Number Field+B)', id: 0, value: 'sum', type: 'fields' },
		{ label: 'Difference between fields (B-Number Field)', id: 1, value: 'difference', type: 'fields' },
		{ label: 'Multiply the fields (Number Field*B)', id: 2, value: 'multiply', type: 'fields' },
		{ label: 'Divide the fields (B/Number Field)', id: 3, value: 'divide', type: 'fields' },
	];
	const getFields = component => {
		let items = [];

		component.fields
			.filter(f => f.name === 'Number')
			.filter(field => {
				items = [
					...items,
					{
						label: field.value,
						value: field.value,
						blueprint_name: component.name,
						blueprint_id: component.id,
					},
				];
			});

		setMultiselectItems([...R.sortWith([R.ascend(R.prop('blueprint_name'))])(items)]);
		setLoadingComponents(false);
	};
	const modulesArray = blueprints
		.filter(bp => bp.fields.filter(f => f.name === 'Number').length > 0)
		.map(elem => {
			return { label: elem.name, value: elem.id, component: elem };
		});

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Optional: Module where to conduct cross-value computations'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={modules}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							setSelectedType(selection);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			{components?.filter(com => com.module_id === selectedType.value).length > 0 && (
				<OptionSection errorMessage={validationErrors?.position_id}>
					<RenderTitle title={'Field where to perform computation? (B)'} />
					<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
						<SelectD
							items={
								components?.filter(com => com.module_id === selectedType.value).length > 0
									? components
										?.filter(com => com.module_id === selectedType.value)[0]
										.component_fields.map(com => {
											return { label: com.field_name, value: com.id };
										})
									: []
							}
							defaultSelected={{ label: defaultKey }}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								onKeySelected?.(selection.value ?? 'Key');
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{components?.filter(com => com.module_id === selectedType.value).length > 0 && (
				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Operation to perform'} />
					<UnfocusableWrapper apps-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={operatorArray}
							defaultSelectedItem={operatorArray[0]}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							onChange={selection => {
								// setDefaultV(selection.value);
								// onOperatorSelected(selection.value);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			<p>{`Notes: This will update the values in the module and transactions affected`}</p>
		</div>
	);
};

export const InfoSelect = props => {
	const { defaultOrientation, defaultKey, defaultType, defaultList, onKeySelected, onTypeSelected, validationErrors, blueprints, onChangeList, multiSelectLabel = 'Default', onChangeOrientation } = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [selectedType, setSelectedType] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [, setLoadingComponents] = useState(true);
	const [multiselectItems, setMultiselectItems] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, blueprint_name: multiSelectLabel };
				})
	);
	const getFields = component => {
		let items = [];
		component.fields.filter(field => {
			items = [
				...items,
				{
					label: field.value,
					value: field.value,
					blueprint_name: component.name,
					blueprint_id: component.id,
				},
			];
		});

		setMultiselectItems([
			...R.sortWith([R.ascend(R.prop('blueprint_name'))])(items),
			{
				label: 'Created Date',
				value: 'created_date',
				blueprint_name: 'Others',
			},
		]);
		setLoadingComponents(false);
	};

	const modulesArray = isOnboarding ? blueprints
		.map(elem => {
			return { label: elem.name, value: elem.id, component: elem };
		}) :
		blueprints
			.filter(bf => bf.readonly === false)
			.map(elem => {
				return { label: elem.name, value: elem.id, component: elem };
			});

	const orientationArray = [
		{ label: 'Rows', value: 'rows' },
		{ label: 'Columns', value: 'Columns' },
	];
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Component where to conduct lookup'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={modulesArray}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							getFields(selection.component);
							setSelectedType(selection);
							onTypeSelected?.(selection ?? 'single');
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What key to use?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<SelectD
						items={multiselectItems}
						defaultSelected={{ label: defaultKey }}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							onKeySelected?.(selection.value ?? 'Key');
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What fields to display?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<MultiSelectWithCheckboxesAndNestedItems_v2
						//placeholder={'Select fields...'}
						items={multiselectItems}
						groupedBy={'blueprint_name'}
						groupname={'blueprint_name'}
						triggerType={'chevron'}
						initialSelectedValues={
							defaultList === undefined
								? []
								: defaultList.length === 0
									? []
									: defaultList.map(d => {
										return { label: d, value: d, blueprint_name: multiSelectLabel };
									})
						}
						className={cls.peoplePositionsSelect}
						inputClassName={cls.peoplePositionsSelectInput}
						molclassname={cls.peoplePositionsSelectMenuOverlay}
						moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
						onChange={selection => {
							onChangeList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Interface Orientation'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={orientationArray}
						defaultSelectedItem={orientationArray.find(o => o.value === defaultOrientation)}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onChangeOrientation(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
		</div>
	);
};

export const ChartSelect = props => {
	const { defaultOrientation, defaultKey, defaultType, defaultList, onKeySelected, onTypeSelected, validationErrors, blueprints, onChangeList, multiSelectLabel = 'Default', onChangeOrientation } = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [selectedType, setSelectedType] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [, setLoadingComponents] = useState(true);
	const [type, setType] = useState(defaultOrientation ?? 'Donut');

	const [multiselectItems, setMultiselectItems] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, blueprint_name: multiSelectLabel };
				})
	);
	const getFields = component => {
		let items = [];
		component.fields.filter(field => {
			items = [
				...items,
				{
					label: field.value,
					value: field.value,
					blueprint_name: component.name,
					blueprint_id: component.id,
				},
			];
		});

		setMultiselectItems([
			...R.sortWith([R.ascend(R.prop('blueprint_name'))])(items),
			{
				label: 'Created Date',
				value: 'created_date',
				blueprint_name: 'Others',
			},
		]);
		setLoadingComponents(false);
	};

	const modulesArray = isOnboarding ? blueprints
		.map(elem => {
			return { label: elem.name, value: elem.id, component: elem };
		}) :
		blueprints
			.filter(bf => bf.readonly === false)
			.map(elem => {
				return { label: elem.name, value: elem.id, component: elem };
			});

	const orientationArray = [
		// { label: 'Line', value: 'Line' },
		{ label: 'Donut', value: 'Donut' },
		// { label: 'Column', value: 'Column' },
	];
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Chart Type'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={orientationArray}
						defaultSelectedItem={orientationArray.find(o => o.value === defaultOrientation)}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onChangeOrientation(selection.value);
							setType(selection.value)
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Component where to get fields'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectD
						items={modulesArray}
						defaultSelected={defaultType}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							getFields(selection.component);
							setSelectedType(selection);
							onTypeSelected?.(selection ?? 'single');
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What key to use?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<SelectD
						items={multiselectItems}
						defaultSelected={{ label: defaultKey }}
						className={cls.dropdownSelectComponent}
						tmiClassName={cls.dropdownInput}
						molclassname={cls.dropdownSelectMenuOverlayList}
						moliclassname={cls.dropdownSelectMenuOverlayListItem}
						onChange={selection => {
							onKeySelected?.(selection.value ?? 'Key');
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			{type !== 'Donut' && <OptionSection errorMessage={validationErrors?.position_id}>
				<RenderTitle title={'What fields to display?'} />
				<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
					<MultiSelectWithCheckboxesAndNestedItems_v2
						//placeholder={'Select fields...'}
						items={multiselectItems}
						groupedBy={'blueprint_name'}
						groupname={'blueprint_name'}
						triggerType={'chevron'}
						initialSelectedValues={
							defaultList === undefined
								? []
								: defaultList.length === 0
									? []
									: defaultList.map(d => {
										return { label: d, value: d, blueprint_name: multiSelectLabel };
									})
						}
						className={cls.peoplePositionsSelect}
						inputClassName={cls.peoplePositionsSelectInput}
						molclassname={cls.peoplePositionsSelectMenuOverlay}
						moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
						onChange={selection => {
							onChangeList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>}

		</div>
	);
};

export const DropdownSelectFormulas = props => {
	const { defaultValue, defaultList, defaultSearchText, onChangeSearchText, onChangeList, onOperatorSelected, validationErrors, selectedFields, blueprints, selectLabel = 'Fields' } = props;

	const [defaultV, setDefaultV] = useState(defaultValue === undefined ? '' : defaultValue);
	const [defaultL] = useState(defaultList === undefined ? [] : defaultList);
	const [defaultS, setDefaultS] = useState(defaultSearchText === undefined ? '' : defaultSearchText);
	const operatorArray = [
		{ label: 'Sum of the fields (A+B)', id: 0, value: 'sum', type: 'fields' },
		{ label: 'Sum of custom fields (F1+..Fn)', id: 15, value: 'freesum', type: 'fields' },
		{ label: 'Difference between fields (A-B)', id: 1, value: 'difference', type: 'fields' },
		{ label: 'Multiply the fields (A*B)', id: 2, value: 'multiply', type: 'fields' },
		{ label: 'Divide the fields (A/B)', id: 3, value: 'divide', type: 'fields' },
		{ label: 'Total Difference per field (A1-A2-An)', id: 4, value: 'tdiff', type: 'fields' },
		{ label: 'Total Sum per field (A1+A2+An)', id: 5, value: 'tsum', type: 'fields' },
		{ label: 'Total Sum per item variable (A1+A2+An)', id: 6, value: 'itemsum', type: 'items' },
		{ label: 'Total Difference per item variable (A1-A2-An)', id: 7, value: 'itemdiff', type: 'items' },
		{ label: 'Difference between dates (D1-D2-Dn, in days)', id: 10, value: 'datesdiff', type: 'date' },
		{ label: 'Difference between today and date (Today-Dn, in days)', id: 11, value: 'datenowdiff', type: 'date' },
		{ label: 'Count occurrences of string in field selected', id: 12, value: 'countocc', type: 'fields' },
		{ label: 'Count occurrences of date in field selected', id: 13, value: 'countdateocc', type: 'date' },
		{ label: 'Compute the distance between two pins', id: 14, value: 'cmpdistance', type: 'pin' },
	];

	const itemsObjArray = [
		{ label: 'Quantity', id: 6, value: 'qty', type: 'items' },
		{ label: 'Total', id: 8, value: 'total', type: 'items' },
		{ label: 'Value', id: 8, value: 'value', type: 'items' },
		{ label: 'Alt Value', id: 9, value: 'altvalue', type: 'items' },
	];

	const dateValuesArray = [
		{ label: 'Set date today', value: 'today', type: 'Date' },
		{ label: 'Set date tomorrow', value: 'tomorrow', type: 'Date' },
		{ label: 'Set date one month from today', value: 'month', type: 'Date' },
		{ label: 'Set date one week from today', value: 'week', type: 'Date' },
		{ label: 'None', value: 'none', type: 'Date' },
	];

	const fieldsArray = selectedFields
		.filter(field => field.value != '' && (field.field_type.name === 'Value Button' || field.field_type.name === 'Number' || field.field_type.name === 'Formula'))
		.map(elem => {
			return { label: elem.value, value: elem.id, field_name: elem.field_type.name };
		});
	const datesArray = selectedFields
		.filter(field => field.value != '' && field.field_type.name === 'Date')
		.map(elem => {
			return { label: elem.value, value: elem.id, field_name: 'Dates' };
		});

	const allFieldsArray = [].concat
		.apply(
			[],
			blueprints.map(elem => {
				return [].concat.apply([], elem.fields);
			})
		)
		.map(y => {
			return { label: y.value, value: y.id, type: y.name };
		});

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Operation to perform'} />
				<UnfocusableWrapper apps-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={operatorArray}
						defaultSelectedItem={operatorArray.find(c => c.value === defaultV)}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						onChange={selection => {
							setDefaultV(selection.value);
							onOperatorSelected(selection.value);
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>

			{defaultV !== 'freesum' && <OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={defaultV === 'sum' || defaultV === 'difference' || defaultV === 'multiply' || defaultV === 'divide' ? 'Fields to perform selected operation' : 'Field to perform selected operation'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					{defaultV === 'datesdiff' || defaultV === 'datenowdiff' || defaultV === 'sum' || defaultV === 'difference' || defaultV === 'multiply' || defaultV === 'divide' ? (
						<MultiSelectWithCheckboxesAndNestedItems_v2
							items={defaultV === 'datesdiff' || defaultV === 'datenowdiff' ? datesArray : fieldsArray}
							groupedBy={'field_name'}
							groupname={'field_name'}
							triggerType={'chevron'}
							initialSelectedValues={defaultV === 'datesdiff' || defaultV === 'datenowdiff' ? datesArray.filter(i => defaultL?.indexOf(i?.label) > -1) : fieldsArray.filter(i => defaultL?.indexOf(i?.label) > -1)}
							className={cls.peoplePositionsSelect}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={selection => {
								onChangeList?.(selection?.map(i => i?.label));
							}}
						/>
					) : defaultV === 'itemdiff' || defaultV === 'itemsum' ? (
						<SelectWithSearch
							triggerType={'chevron'}
							items={itemsObjArray}
							defaultSelectedItem={defaultL.length > 0 ? itemsObjArray.find(obj => obj.value === defaultL[0]) ?? {} : {}}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							onChange={selection => {
								onChangeList?.([selection?.value]);
							}}
						/>
					) : defaultV === 'cmpdistance' ? (
						<MultiSelectWithCheckboxesAndNestedItems_v2
							items={allFieldsArray.filter(f => f.type === 'Pin')}
							groupedBy={'type'}
							groupname={'type'}
							triggerType={'chevron'}
							initialSelectedValues={allFieldsArray.filter(f => f.type === 'Pin').filter(i => defaultL?.indexOf(i?.label) > -1)}
							className={cls.peoplePositionsSelect}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={selection => {
								onChangeList?.(selection?.map(i => i?.label));
							}}
						/>

					) : (
						<SelectWithSearch
							triggerType={'chevron'}
							items={R.sortWith([R.ascend(R.prop('label'))])(
								[...new Set(allFieldsArray.filter(x => (defaultV === 'countdateocc' ? x.type === 'Date' : (x.type === 'Text' && defaultV === 'countocc') || x.type === 'Number' || x.type === 'Status' || x.type === 'Formula' || x.type === 'Currency')).map(val => val.label))].map(
									val => {
										return { label: val, value: val };
									}
								)
							)}
							defaultSelectedItem={defaultL.length > 0 ? { label: defaultL[0] } : {}}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							onChange={selection => {
								onChangeList?.([selection?.label]);
							}}
						/>
					)}
				</UnfocusableWrapper>
			</OptionSection>}
			{defaultV === 'countocc' && (
				<OptionSection errorMessage={validationErrors?.name}>
					<RenderTitle title={'Input text to search'} />
					<input
						type="text"
						name="element-name"
						value={defaultS}
						onChange={e => {
							setDefaultS(e.target.value);
							onChangeSearchText(e.target.value);
						}}
						className={cls.elementTextInput}
					/>
				</OptionSection>
			)}
			{defaultV === 'countdateocc' && (
				<OptionSection errorMessage={validationErrors?.name}>
					<RenderTitle title={'Select date to match with'} />
					<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={dateValuesArray}
							defaultSelectedItem={defaultSearchText && dateValuesArray.find(dv => dv.value === defaultSearchText)}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							onChange={selection => {
								onChangeSearchText(selection?.value);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			<br />
			<p className={cls.elementInputTitle}>{'Ensure fields are set to the appropriate type (number, dates, currency, etc)'}</p>
		</div>
	);
};

export const DropdownSelectEmails = props => {
	const { options, setFieldOption, fieldOption, validationErrors } = props;
	const [subject, setSubject] = useState(options.subject ?? '');
	const [message, setMessage] = useState(options.message ?? '');
	const [message2, setMessage2] = useState(options.message2 ?? '');
	const [valueA, setValueA] = useState(options.valueToMatch ?? '');
	const [copyEmail, setCopyEmail] = useState(options.copyEmailRecip ?? '');
	const [fieldToSet, setFieldToSet] = useState(options.fieldToMatch ?? '');
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.name}>
				<RenderTitle title={'Email subject'} />
				<input
					type="text"
					name="element-name"
					value={subject ?? ''}
					onChange={e => {
						setFieldOption({ ...fieldOption, message2: message2, message: message, subject: e.target.value, copyEmailRecip: copyEmail });
						setSubject(e.target.value);
					}}
					className={cls.elementTextInput}
				/>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.name}>
				<RenderTitle title={'Custom email message to selected recipients'} />
				<textarea
					type="text"
					name="element-name"
					value={message ?? ''}
					onChange={e => {
						setFieldOption({ ...fieldOption, subject: subject, message: e.target.value, message2: message2, copyEmailRecip: copyEmail });
						setMessage(e.target.value);
					}}
					className={cls.elementTextInput}
					style={{ height: '100px' }}
				/>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.name}>
				<RenderTitle title={'Custom email message to creator'} />
				<textarea
					type="text"
					name="element-name"
					value={message2 ?? ''}
					onChange={e => {
						setFieldOption({ ...fieldOption, subject: subject, message: message, message2: e.target.value, copyEmailRecip: copyEmail });
						setMessage2(e.target.value);
					}}
					className={cls.elementTextInput}
					style={{ height: '100px' }}
				/>
			</OptionSection>

			<OptionSection>
				<RenderTitle title={'Field to check to trigger email'} />
				<UnfocusableWrapper className={cls.unfocusableWrapper}>
					<input
						type={'text'}
						className={cls.dropDownNewListItemInput}
						value={fieldToSet}
						name={'matched to field'}
						onChange={e => {
							setFieldToSet(e.target.value);
							setFieldOption({ ...fieldOption, subject: subject, message: message, message2: message2, fieldToMatch: e.target.value, valueToMatch: valueA, copyEmailRecip: copyEmail });
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>

			<OptionSection>
				<RenderTitle title={"Values of field to trigger the email (Note: Use ' , ' to seperate)"} />
				<UnfocusableWrapper className={cls.unfocusableWrapper}>
					<input
						type={'text'}
						className={cls.dropDownNewListItemInput}
						value={valueA}
						name={'matched to field'}
						onChange={e => {
							setValueA(e.target.value);
							setFieldOption({ ...fieldOption, subject: subject, message: message, message2: message2, fieldToMatch: fieldToSet, valueToMatch: e.target.value, copyEmailRecip: copyEmail });
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<RenderYesNoSwitcher
				{...{
					id: 'Copy default email recipients',
					title: 'Copy default email recipients?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={copyEmail}
				toggleSelected={val => {
					setCopyEmail(val);
					setFieldOption({ ...fieldOption, subject: subject, message: message, message2: message2, fieldToMatch: fieldToSet, valueToMatch: valueA, copyEmailRecip: val });
				}}
			/>
		</div>
	);
};

export const ReportsFilter = props => {
	const { defaultValue, defaultField, onChangeField, onChangeValue, validationErrors, blueprints } = props;

	const [defaultV, setDefaultV] = useState(defaultValue === undefined ? '' : defaultValue);
	const [defaultF] = useState(defaultField === undefined ? '' : defaultField);

	const allFieldsArray = [].concat
		.apply(
			[],
			blueprints.map(elem => {
				return [].concat.apply([], elem.fields);
			})
		)
		.map(y => {
			return { label: y.value, value: y.id, type: y.name };
		});

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field to perform filter'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={allFieldsArray}
						defaultSelectedItem={defaultF.length > 0 ? { label: defaultF } : {}}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onChangeField?.(selection?.label);
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.name}>
				<RenderTitle title={'Input text to search'} />
				<input
					type="text"
					name="element-name"
					value={defaultV}
					onChange={e => {
						onChangeValue(e.target.value);
						setDefaultV(e.target.value);
					}}
					className={cls.elementTextInput}
				/>
			</OptionSection>
			<br />
		</div>
	);
};

export const DropdownSelectProductLookup = props => {
	const { defaultRefName, onReferenceLabel, validationErrors, onTargetSelected, onChangeList, onTypeSelected, defaultTarget, defaultType, bpContext, defaultList } = props;

	const [, setCleared] = useState(false);
	const [, setTargetV] = useState({});

	const [selectedReference, setSelectedReference] = useState(
		defaultTarget == undefined
			? []
			: defaultTarget?.fields?.map(field => {
				return { label: field.value, value: field.value };
			})
	);

	const [selectedFieldOutputArray, setSelectedFieldOutputArray] = useState(
		defaultList == undefined
			? []
			: defaultList?.length > 0
				? defaultList.map(d => {
					return { label: d, value: d, component_name: 'Fields' };
				})
				: []
	);

	const [componentsBlueprintArray] = useState(
		bpContext.allBlueprintComponents
			.filter(bf => bf.readonly === false)
			.filter(bpc => bpc.fields.filter(field => field.value === 'Product ID').length > 0)
			.map(elem => {
				return { label: elem.name, value: elem.id, blueprint: elem };
			})
	);

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Component where to conduct lookup of Product'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={componentsBlueprintArray}
						defaultSelectedItem={{ label: defaultTarget?.name, value: defaultTarget?.id }}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							setSelectedReference(
								selection.blueprint.fields.map(f => {
									return { label: f.value, value: f.value };
								})
							);
							setSelectedFieldOutputArray(
								selection.blueprint.fields.map(f => {
									return { label: f.value, value: f.value };
								})
							);
							setTargetV(selection);
							onTargetSelected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Display label for Components found'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={selectedReference}
						defaultSelectedItem={{ label: defaultRefName, value: defaultRefName }}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onReferenceLabel(selection.value);
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Fields to output'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<MultiselectWithSearchR
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						tagType={'shadowed' /* | "step-plated" | "plated" */}
						tagEmplacement={'inlined'}
						items={selectedFieldOutputArray}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						initialSelectedItems={
							defaultList?.length === 0
								? []
								: defaultList.map(d => {
									return { label: d, value: d };
								})
						}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onChangeList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Interface Type'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={[
							{ label: 'Dropdown', value: 'Dropdown' },
							{ label: 'Tiles', value: 'Tiles' },
							{ label: 'Columns', value: 'Columns' },
						]}
						defaultSelectedItem={defaultType}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						//placeholder={'Select type...'}
						onChange={selection => {
							onTypeSelected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
		</div>
	);
};

export const FiltersComponent = props => {
	const { validationErrors, onTargetSelected, onOutputSelected, onGlobalSelected, defaultTarget, defaultGlobal, bpContext, onFilterList, defaultFilters, defaultOutput } = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [, setCleared] = useState(false);
	const [, setTargetV] = useState({});

	const [selectedFieldFiltersArray, setSelectedFieldFiltersArray] = useState(
		defaultFilters == undefined
			? []
			: defaultFilters?.length > 0
				? defaultFilters.map(d => {
					return { label: d, value: d, component_name: 'Fields' };
				})
				: []
	);
	const [componentsBlueprintArray] = useState(isOnboarding ?
		bpContext.allBlueprintComponents
			.map(elem => {
				return { label: elem.name, value: elem.id, blueprint: elem };
			})
		:
		bpContext.allBlueprintComponents
			.filter(bf => bf.readonly === false)
			.map(elem => {
				return { label: elem.name, value: elem.id, blueprint: elem };
			})
	);
	const [selectedFieldOutputArray, setSelectedFieldOutputArray] = useState(
		defaultTarget == undefined
			? []
			: defaultTarget.fields.map(f => {
				return { label: f.value, value: f.value };
			})
	);

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Component where to conduct lookup'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={componentsBlueprintArray}
						defaultSelectedItem={{ label: defaultTarget?.name, value: defaultTarget?.id }}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							setSelectedFieldFiltersArray(
								selection.blueprint.fields.map(f => {
									return { label: f.value, value: f.value };
								})
							);
							setSelectedFieldOutputArray(
								selection.blueprint.fields.map(f => {
									return { label: f.value, value: f.value };
								})
							);
							setTargetV(selection);
							onTargetSelected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection>
				<RenderTitle title={'Fields to filter (enter in order of filter, max 3)'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<MultiselectWithSearchR
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						tagType={'shadowed' /* | "step-plated" | "plated" */}
						tagEmplacement={'inlined'}
						items={selectedFieldFiltersArray}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						initialSelectedItems={
							defaultFilters?.length === 0
								? []
								: defaultFilters?.map(d => {
									return { label: d, value: d };
								})
						}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onFilterList?.(selection?.map(i => i?.value));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Use a global field to filter components (Optional)'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={selectedFieldOutputArray}
						defaultSelectedItem={{ label: defaultGlobal, value: defaultGlobal }}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onGlobalSelected(selection.value);
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Field value to save as output'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={selectedFieldOutputArray}
						defaultSelectedItem={{ label: defaultOutput, value: defaultOutput }}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onOutputSelected(selection.value);
						}}
					/>
				</div>
			</OptionSection>
		</div>
	);
};

export const DropdownSelectLookup = props => {
	const {
		defaultGlobal,
		defaultRefName,
		defaultSrcName,
		onSourceLabel,
		onReferenceLabel,
		defaultValue,
		validationErrors,
		onSourceSelected,
		onTargetSelected,
		onFieldSelected,
		onChangeList,
		onTypeSelected,
		defaultCommon,
		defaultTarget,
		defaultType,
		bpContext,
		defaultList,
		defaultRefList,
		onChangeRList,
		onGlobalSelected,
		onSetShowAll,
		showAllDefault
	} = props;

	const [refresh, setRefresh] = useState('none');
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [, setCleared] = useState(false);
	const [sourceV, setSourceV] = useState({});
	const [, setTargetV] = useState({});
	const [, setFieldV] = useState({});
	const [selectedFieldsArray, setSelectedFieldsArray] = useState([]);
	const [selectedTargetArray, setSelectedTargetArray] = useState([]);
	const [selectedReference, setSelectedReference] = useState([]);
	const [selectedReferenceList, setSelectedReferenceList] = useState(
		defaultRefList === undefined
			? []
			: defaultRefList.length === 0
				? []
				: defaultRefList.map(d => {
					return { label: d, value: d, component_name: 'Fields' };
				})
	);
	const [selectedFieldOutputArray, setSelectedFieldOutputArray] = useState(
		defaultList === undefined
			? []
			: defaultList.length === 0
				? []
				: defaultList.map(d => {
					return { label: d, value: d, component_name: 'Fields' };
				})
	);

	const [componentsBlueprintArray, setComponentsBlueprintArray] = useState([]);

	useEffect(() => {
		retreiveComponents();
	}, []);

	const retreiveComponents = useCallback(() => {
		const found = isOnboarding ?
			bpContext.allBlueprintComponents
				.map(elem => {
					return { label: elem.name, value: elem.id, blueprint: elem };
				})
			: bpContext.allBlueprintComponents
				.filter(bpc => bpc.readonly == false)
				.map(elem => {
					return { label: elem.name, value: elem.id, blueprint: elem };
				});
		setComponentsBlueprintArray(found);

		if (defaultValue && found) {
			const fields = found
				.find(cb => cb.label === defaultValue.label)
				?.blueprint.fields.map(f => {
					return { label: f.value, value: f.id, field: f };
				});
			const cmp = found.find(cb => cb.label === defaultValue.label);
			setSelectedFieldsArray(cmp.blueprint.fields.map(f => {
				return { label: f.value, value: f.id, field: f, component_name: cmp.blueprint?.name };
			}));
			if (defaultCommon && fields?.length > 0) {
				const targets = found
					?.filter(cb => cb?.blueprint.fields?.filter(f => f.value === defaultCommon.label).length > 0)
					.filter(cm => cm.label !== defaultValue.label)
					.map(cmp => {
						return { label: cmp.label, value: cmp.value, component: cmp.blueprint };
					});
				setSelectedTargetArray(targets);
				if (defaultTarget && targets?.length > 0) {
					setSelectedReference(
						targets
							?.find(t => t.label === defaultTarget.label)
							?.component?.fields?.map(f => {
								return { label: f.value, value: f.value, component_name: defaultTarget.label };
							}) ?? []
					);

					const cmp = found.find(cb => cb.label === defaultValue.label);
					setSelectedFieldOutputArray(
						targets
							?.find(t => t.label === defaultTarget.label)
							?.component.fields?.map(f => {
								return { label: f.value, value: f.value, component_name: defaultTarget.label };
							})
							.concat(
								cmp?.blueprint.fields.map(f => {
									return { label: f.value, value: f.value, component_name: cmp.label };
								})
							) ?? []
					);
				}
			}
		}
		setTimeout(() => setRefresh(''));
	});

	return (
		refresh === '' && (
			<div className={cls.dropdownElementHost} style={{ display: refresh }}>
				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Category Component'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={componentsBlueprintArray}
							defaultSelectedItem={defaultValue}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								const cmp = componentsBlueprintArray.find(cb => cb.value === selection.value);
								setSelectedFieldsArray(
									cmp.blueprint.fields.map(f => {
										return { label: f.value, value: f.id, field: f, component_name: cmp.blueprint?.name };
									})
								);
								setSourceV(selection);
								onSourceSelected(selection.value);
							}}
						/>
					</div>
				</OptionSection>

				<OptionSection>
					<RenderTitle title={'Display label for Category Component'} />
					<UnfocusableWrapper app-variant={'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
						<MultiSelectWithCheckboxesAndNestedItems_v2
							tagEmplacement={'inlined'}
							items={selectedFieldsArray ?? []}
							groupedBy={'component_name'}
							groupname={'component_name'}
							triggerType={'chevron'}
							initialSelectedValues={
								defaultSrcName === undefined
									? []
									: defaultSrcName.length === 0
										? []
										: defaultSrcName.map(d => {
											return selectedFieldsArray?.find(sf => sf.label === d);
										})
							}
							className={cls.peoplePositionsSelect}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={selection => {
								onSourceLabel?.(selection?.map(i => i?.label));
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>

				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Common field for lookup'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={selectedFieldsArray}
							defaultSelectedItem={defaultCommon}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								if (selection) {
									const cmpArray = isOnboarding ?
										componentsBlueprintArray.filter(cb => cb?.blueprint?.fields?.filter(f => f.value === selection.label).length > 0) :
										componentsBlueprintArray.filter(cb => cb.blueprint.readonly === false).filter(cb => cb?.blueprint?.fields?.filter(f => f.value === selection.label).length > 0);

									setSelectedTargetArray(
										cmpArray
											.filter(cm => cm.label !== sourceV.label)
											.map(cmp => {
												return { label: cmp.label, value: cmp.value, component: cmp.blueprint };
											})
									);
									setFieldV(selection);
									onFieldSelected(selection.label);
									if (selection?.symbol) {
										setCleared(false);
									} else {
										setCleared(true);
									}
								}
							}}
						/>
					</div>
				</OptionSection>
				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Component where to conduct lookup'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={selectedTargetArray}
							defaultSelectedItem={defaultTarget}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								setSelectedReference(
									selection.component.fields.map(f => {
										return { label: f.value, value: f.value, component_name: selection.label };
									}) ?? []
								);
								setSelectedFieldOutputArray(
									selection.component.fields
										.map(f => {
											return { label: f.value, value: f.value, component_name: selection.label };
										})
										.concat(
											sourceV.blueprint.fields.map(f => {
												return { label: f.value, value: f.value, component_name: sourceV.label };
											})
										)
								);
								setTargetV(selection);
								onTargetSelected(selection.value);
								if (selection?.symbol) {
									setCleared(false);
								} else {
									setCleared(true);
								}
							}}
						/>
					</div>
				</OptionSection>

				<OptionSection>
					<RenderTitle title={'Use a global field to filter components (Optional)'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={selectedFieldOutputArray}
							defaultSelectedItem={{ label: defaultGlobal, value: defaultGlobal }}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onGlobalSelected(selection.value);
							}}
						/>
					</div>
				</OptionSection>


				<OptionSection>
					<RenderTitle title={'Fields to display for Components found'} />
					<UnfocusableWrapper app-variant={'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
						<MultiSelectWithCheckboxesAndNestedItems_v2
							tagEmplacement={'inlined'}
							items={selectedReference ?? []}
							groupedBy={'component_name'}
							groupname={'component_name'}
							triggerType={'chevron'}
							initialSelectedValues={
								defaultRefList === undefined
									? []
									: defaultRefList.length === 0
										? []
										: defaultRefList.map(d => {
											return { label: d, value: d, component_name: 'Fields' };
										})
							}
							className={cls.peoplePositionsSelect}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={selection => {
								onChangeRList?.(selection?.map(i => i?.value));
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
				<OptionSection errorMessage={validationErrors?.accepted_mimes}>
					<RenderTitle title={'Fields to output'} />
					<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
						<MultiSelectWithCheckboxesAndNestedItems_v2
							tagEmplacement={'inlined'}
							items={selectedFieldOutputArray}
							groupedBy={'component_name'}
							groupname={'component_name'}
							triggerType={'chevron'}
							initialSelectedValues={selectedFieldOutputArray?.filter(sf => defaultList?.indexOf(sf.label) !== -1)}
							className={cls.peoplePositionsSelect}
							inputClassName={cls.peoplePositionsSelectInput}
							molclassname={cls.peoplePositionsSelectMenuOverlay}
							moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
							onChange={selection => {
								onChangeList?.(selection?.map(i => i?.value));
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Interface Type'} />
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={[
								{ label: 'Dropdown', value: 'Dropdown' },
								{ label: 'Tiles', value: 'Tiles' },
							]}
							defaultSelectedItem={defaultType}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onTypeSelected(selection.value);
								if (selection?.symbol) {
									setCleared(false);
								} else {
									setCleared(true);
								}
							}}
						/>
					</div>
				</OptionSection>
				<RenderYesNoSwitcher
					{...{
						id: 'Show all choices immediately',
						title: 'Show all choices immediately?',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
					value={showAllDefault}
					toggleSelected={val => {
						onSetShowAll(val);
					}}
				/>
			</div>
		)
	);
};
export const DropdownSelectConnector = props => {
	const {
		defaultInputComponent1,
		defaultComponent1,
		defaultComponent2,
		defaultComponent3,
		defaultCommon12,
		defaultCommon23,
		defaultOutputs = [],
		onComponent1Selected,
		onComponent1InputSelected,
		onComponent2Selected,
		onComponent3Selected,
		onCommon12Selected,
		onCommon23Selected,
		onOutputsSelected,
		validationErrors,
		bpContext,
	} = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);

	const [componentsBlueprintArray] = useState(isOnboarding ?
		bpContext.allBlueprintComponents
			.map(elem => {
				return { label: elem.name, value: elem.id, blueprint: elem };
			})
		:
		bpContext.allBlueprintComponents
			.filter(bf => bf.readonly === false)
			.map(elem => {
				return { label: elem.name, value: elem.id, blueprint: elem };
			})
	);
	const [, setCleared] = useState(false);

	const [component1, setComponent1] = useState({});

	const [common12Array, setCommon12Array] = useState(
		defaultComponent1
			? componentsBlueprintArray
				.find(cb => cb.label === defaultComponent1.label)
				.blueprint.fields.map(f => {
					return { label: f.value, value: f.id, field: f, component_name: defaultComponent1.label };
				})
			: []
	);
	const [common23Array, setCommon23Array] = useState(
		defaultComponent2
			? componentsBlueprintArray
				.find(cb => cb.label === defaultComponent2.label)
				.blueprint.fields.map(f => {
					return { label: f.value, value: f.id, field: f, component_name: defaultComponent2.label };
				})
			: []
	);

	const [component2Array, setComponent2Array] = useState([]);
	const [component3Array, setComponent3Array] = useState([]);

	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Input Component (A)'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={componentsBlueprintArray}
						defaultSelectedItem={defaultComponent1}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							const cmp = componentsBlueprintArray.find(cb => cb.value === selection.value);
							setCommon12Array(
								cmp.blueprint.fields.map(f => {
									return { label: f.value, value: f.id, field: f };
								})
							);
							setComponent1(selection);
							onComponent1Selected(selection.value);
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Input field to use with component (A)'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={common12Array}
						defaultSelectedItem={defaultInputComponent1}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onComponent1InputSelected(selection.label);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Common field for first lookup, A + B'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={common12Array}
						defaultSelectedItem={defaultCommon12}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							const cmpArray = componentsBlueprintArray.filter(cb => cb.blueprint.fields.filter(f => f.value === selection.label).length > 0);
							setComponent2Array(
								cmpArray
									.filter(cm => cm.label !== component1.label)
									.map(cmp => {
										return { label: cmp.label, value: cmp.value, component: cmp.blueprint };
									})
							);
							onCommon12Selected(selection.label);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Target component (B) where value will come from'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={component2Array}
						defaultSelectedItem={defaultComponent2}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							const cmp = componentsBlueprintArray.find(cb => cb.value === selection.value);
							setCommon23Array(
								cmp.blueprint.fields.map(f => {
									return { label: f.value, value: f.id, field: f, component_name: cmp.blueprint.name };
								})
							);

							onComponent2Selected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Common field for second lookup, B + C'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={common23Array}
						defaultSelectedItem={defaultCommon23}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							const cmpArray = componentsBlueprintArray.filter(cb => cb.blueprint.fields.filter(f => f.value === selection.label).length > 0);
							setComponent3Array(
								cmpArray
									.filter(cm => cm.label !== component1.label)
									.map(cmp => {
										return { label: cmp.label, value: cmp.value, component: cmp.blueprint };
									})
							);
							onCommon23Selected(selection.label);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Component (C) where to conduct second lookup'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={component3Array}
						defaultSelectedItem={defaultComponent3}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onComponent3Selected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Fields to display from target component (B)'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<MultiSelectWithCheckboxesAndNestedItems_v2
						tagEmplacement={'inlined'}
						items={common23Array}
						groupedBy={'component_name'}
						groupname={'component_name'}
						triggerType={'chevron'}
						initialSelectedValues={
							defaultOutputs === undefined
								? []
								: defaultOutputs.length === 0
									? []
									: defaultOutputs.map(d => {
										return { label: d, value: common23Array.find(c => c.label === d)?.value, component_name: defaultComponent2.label };
									})
						}
						className={cls.peoplePositionsSelect}
						inputClassName={cls.peoplePositionsSelectInput}
						molclassname={cls.peoplePositionsSelectMenuOverlay}
						moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
						onChange={selection => {
							onOutputsSelected?.(selection?.map(i => i?.label));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};
export const UploadSelect = props => {
	const { defaultMaxSize, onMaxFileSizeChanged, defaultList, onListSelected, validationErrors } = props;
	const [maxSize, setMaxSize] = useState((defaultMaxSize ?? 0)?.toString());
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.max_file_size}>
				<RenderTitle title={'Maximum file size'} />
				<UnfocusableWrapper app-variant={validationErrors?.max_file_size ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
					<input
						type={'text'}
						placeholder={'Insert max size in MB'}
						className={cls.maxFileSizeInput}
						value={maxSize}
						name={'file max size'}
						onChange={e => {
							const newNumber = e.target.value;
							if (Number(+newNumber)) {
								setMaxSize(newNumber);
								onMaxFileSizeChanged?.(newNumber);
							} else if (newNumber === '') {
								setMaxSize('');
								onMaxFileSizeChanged?.('0');
							}
						}}
					/>
					<pre className={cls.uploadFileSizeMeasureUnit}>{'MB'}</pre>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.accepted_mimes}>
				<RenderTitle title={'Type of Files'} />
				<UnfocusableWrapper app-variant={validationErrors?.accepted_mimes ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<MultiselectWithSearchR
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						tagType={'shadowed' /* | "step-plated" | "plated" */}
						tagEmplacement={'top'}
						items={fileExtensions}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						initialSelectedItems={fileExtensions?.filter(i => defaultList?.indexOf(i?.value?.replaceAll('.', '')) > -1)}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onListSelected?.(selection.map(i => i?.value?.replaceAll('.', '')).join(','));
						}}
					/>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};
const RenderEditableListItem = props => {
	const { item, onItemChanged, onItemRemoved, isOverflow } = props;
	const [value, setValue] = useState(item);
	const [showControls, setShowControls] = useState(false);
	return (
		<UnfocusableWrapper
			className={cls.renderEditableListContainer}
			app-variant={isOverflow ? 'overflow' : ''}
			onClickOutside={() => {
				setShowControls(false);
				setValue(item);
			}}
		>
			<input
				onFocus={() => {
					setShowControls(true);
				}}
				type={'text'}
				className={cls.renderEditableListItemInput}
				value={value}
				name={'editItem'}
				onChange={e => {
					setShowControls(true);
					setValue(e.target.value);
				}}
			/>
			{showControls && (
				<button
					type={'button'}
					onClick={_e => {
						onItemChanged?.(value);
					}}
					className={cls.renderEditableListItemDeleteButton}
				>
					<CheckedStyle />
				</button>
			)}
			{showControls && (
				<button
					type={'button'}
					onClick={_e => {
						onItemRemoved?.(item);
					}}
					className={cls.renderEditableListItemDeleteButton}
				>
					<DeleteBin />
				</button>
			)}
		</UnfocusableWrapper>
	);
};
export const RecordsSelect = props => {
	const { defaultFilters, defaultValue, nameValues, defaultList, onListSelected, onComponentSelected, onGlobalSelected, blueprints, onTypeSelected, defaultType, defaultOutputField, defaultGlobal, onNamesSelected, onOutputFieldSelected, onFilterList } = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [selected, setSelected] = useState(undefined);
	const [cleared, setCleared] = useState(false);
	const [componentsBlueprintArray] = useState(
		isOnboarding ? blueprints?.map(elem => {
			return { label: elem.name, value: elem.id, blueprint: elem };
		}) ?? []
			:
			blueprints.filter(bf => bf.readonly === false).length > 0
				? blueprints
					.filter(bf => bf.readonly === false)
					.map(elem => {
						return { label: elem.name, value: elem.id, blueprint: elem };
					})
				: blueprints?.map(elem => {
					return { label: elem.name, value: elem.id, blueprint: elem };
				}) ?? []
	);

	const [selectedFieldFiltersArray, setSelectedFieldFiltersArray] = useState(
		defaultFilters == undefined
			? []
			: defaultFilters?.length > 0
				? defaultFilters.map(d => {
					return { label: d, value: d, component_name: 'Fields' };
				})
				: []
	);
	const [fields, setFields] = useState(
		defaultValue?.label
			? blueprints.length == 0
				? []
				: isOnboarding ? blueprints
					.find(bp => bp.name === defaultValue.label)
					?.fields?.map(f => {
						return { label: f.value, value: f.value };
					}) : blueprints
						.filter(bf => bf.readonly === false)
						.find(bp => bp.name === defaultValue.label)
						?.fields?.map(f => {
							return { label: f.value, value: f.value };
						})
			: []
	);
	return (
		<Fragment>
			<OptionSection>
				<RenderTitle title={'Interface Type'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={[
							{ label: 'Dropdown', value: 'Dropdown' },
							{ label: 'Tiles', value: 'Tiles' },
						]}
						defaultSelectedItem={defaultType}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onTypeSelected(selection.value);
							if (selection?.symbol) {
								setCleared(false);
							} else {
								setCleared(true);
							}
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection>
				<RenderTitle title={'Component where to conduct lookup'} />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div className={cls.currencySelectContainer}>
						<pre className={cls.currencySymbol} style={{ display: 'none' }}>
							{selected?.symbol ? selected?.symbol : cleared ? '' : defaultValue?.symbol}{' '}
						</pre>
						<SelectWithSearch
							triggerType={'chevron'}
							items={componentsBlueprintArray}
							defaultSelectedItem={defaultValue}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								setSelected(selection);
								onComponentSelected(selection);
								if (selection?.symbol) {
									setCleared(false);
								} else {
									setCleared(true);
								}

								setFields(
									selection.blueprint.fields.map(f => {
										return { label: f.value, value: f.value };
									})
								);
								setSelectedFieldFiltersArray(
									selection.blueprint.fields.map(f => {
										return { label: f.value, value: f.value };
									})
								);
							}}
						/>
					</div>
				</div>
			</OptionSection>
			<OptionSection>
				<RenderTitle title={'Values to display'} />
				<div className={cls.currencySelectContainer}>
					<MultiselectWithSearchR
						triggerType={'chevron'}
						labelsTag={true}
						tagCloseButtonType={'circleX'}
						tagType={'shadowed'}
						tagEmplacement={'inlined'}
						items={fields}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						inputStyle={{ cursor: 'pointer' }}
						initialSelectedItems={fields?.filter(i => nameValues?.indexOf(i.label) > -1)}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onNamesSelected?.(selection?.map(i => i?.value));
						}}
					/>
				</div>
			</OptionSection>

			<OptionSection>
				<RenderTitle title={'Fields to filter, enter in order of filter, max 3 (Optional)'} />
				<UnfocusableWrapper app-variant={'normal'} className={classNames(cls.unfocusableWrapper, cls.multiselectContainer)}>
					<MultiselectWithSearchR
						labelsTag={true}
						tagCloseButtonType={'circleX' /* | "X" */}
						tagType={'shadowed' /* | "step-plated" | "plated" */}
						tagEmplacement={'inlined'}
						items={selectedFieldFiltersArray}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						initialSelectedItems={
							defaultFilters?.length === 0
								? []
								: defaultFilters?.map(d => {
									return { label: d, value: d };
								})
						}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						inputStyle={{ cursor: 'pointer' }}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onFilterList?.(selection?.map(i => i?.value));
						}}
					>
						<FontAwesomeIcon className="fa-icon" icon={faChevronDown} style={{ marginRight: '20px', color: 'gray' }} />
					</MultiselectWithSearchR>
				</UnfocusableWrapper>
			</OptionSection>
			<OptionSection>
				<RenderTitle title={'Use a global field to filter components (Optional)'} />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div className={cls.currencySelectContainer}>

						<SelectWithSearch
							triggerType={'chevron'}
							items={fields}
							defaultSelectedItem={{ label: defaultGlobal, value: defaultGlobal }}
							className={cls.componentsSelectComponent}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onGlobalSelected(selection.value);
							}}
						/>
					</div>
				</div>
			</OptionSection>
			<OptionSection>
				<RenderTitle title={'Field value to save'} />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div className={cls.currencySelectContainer}>
						<pre className={cls.currencySymbol} style={{ display: 'none' }}>
							{selected?.symbol ? selected?.symbol : cleared ? '' : defaultValue?.symbol}{' '}
						</pre>
						<SelectWithSearch
							triggerType={'chevron'}
							items={fields}
							defaultSelectedItem={defaultOutputField}
							className={cls.componentsSelectComponent}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem', cursor: 'pointer' }}
							inputClassName={cls.componentsSearchInput}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								onOutputFieldSelected(selection.value);
							}}
						/>
					</div>
				</div>
			</OptionSection>

			<OptionSection>
				<RenderTitle title={'Field values to share'} />
				<div className={cls.currencySelectContainer}>
					<MultiselectWithSearchR
						triggerType={'chevron'}
						labelsTag={true}
						tagCloseButtonType={'circleX'}
						tagType={'shadowed'}
						tagEmplacement={'inlined'}
						items={fields}
						itemType={'checkboxes'}
						checkboxEmplacement={'left'}
						inputStyle={{ cursor: 'pointer' }}
						initialSelectedItems={fields?.filter(i => defaultList?.indexOf(i.label) > -1)}
						className={cls.uploadSelectComponent}
						inputClassName={cls.uploadSearchInput}
						molclassname={cls.uploadSelectMenuOverlayList}
						moliclassname={cls.uploadSelectMenuOverlayListItem}
						onChange={selection => {
							onListSelected?.(selection?.map(i => i?.value));
						}}
					/>
				</div>
			</OptionSection>
		</Fragment>
	);
};

export const ValueSelect = props => {
	const { defaultValue, onModuleSelected, field_type, onOutputSelected, onFieldValueSelected, defaultOutput, defaultModule, components = [], allModules } = props;
	const [selected, setSelected] = useState(undefined);
	const [selectedComponent, setSelectedComponent] = useState(defaultModule ? { value: '' } : undefined);
	const [modules] = useState(
		allModules.map(elem => {
			return { label: elem.name, value: elem.id, module: elem };
		})
	);

	return (
		<Fragment>
			<OptionSection>
				<RenderTitle title={'Select Module:'} />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div className={cls.currencySelectContainer}>
						<SelectWithSearch
							triggerType={'chevron'}
							items={modules}
							defaultSelectedItem={defaultModule}
							className={cls.componentsSelectComponent}
							inputClassName={cls.componentsSearchInput}
							inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
							clearControlsClassName={cls.componentsClearControlsClassName}
							molclassname={cls.componentsSelectMenuOverlayList}
							moliclassname={cls.componentsSelectMenuOverlayListItem}
							onChange={selection => {
								setSelected(selection);
								onModuleSelected(selection);
							}}
						/>
					</div>
				</div>
			</OptionSection>
			{selected && (
				<OptionSection>
					<RenderTitle title={'Select Output:'} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div className={cls.currencySelectContainer}>
							<SelectWithSearch
								triggerType={'chevron'}
								items={components
									?.filter(com => com.module_id === selected.value)
									.map(com => {
										return { label: `${com.title}`, value: com.id };
									})}
								defaultSelectedItem={defaultOutput}
								className={cls.componentsSelectComponent}
								inputClassName={cls.componentsSearchInput}
								inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
								clearControlsClassName={cls.componentsClearControlsClassName}
								molclassname={cls.componentsSelectMenuOverlayList}
								moliclassname={cls.componentsSelectMenuOverlayListItem}
								onChange={selected => {
									setSelectedComponent(selected);
									onOutputSelected(selected);
								}}
							/>
						</div>
					</div>
				</OptionSection>
			)}
			{selectedComponent && (
				<OptionSection>
					<RenderTitle title={'Select Field Value:'} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div className={cls.currencySelectContainer}>
							<SelectWithSearch
								triggerType={'chevron'}
								items={
									components
										?.find(com => com.id === selectedComponent.value)
										?.component_fields?.map(field => {
											return { label: `${field.field_name} = ${field.value}`, value: field.value };
										}) ?? []
								}
								defaultSelectedItem={defaultValue}
								className={cls.componentsSelectComponent}
								inputClassName={cls.componentsSearchInput}
								inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
								clearControlsClassName={cls.componentsClearControlsClassName}
								molclassname={cls.componentsSelectMenuOverlayList}
								moliclassname={cls.componentsSelectMenuOverlayListItem}
								onChange={selected => {
									onFieldValueSelected(selected);
								}}
							/>
						</div>
					</div>
				</OptionSection>
			)}
		</Fragment>
	);
};

export const PeopleSelect = memo(props => {
	const { defaultCalendar, defaultSlider, defaultAssign, defaultEmail, defaultType, defaultPositions, onChangeSlider, onChangeList, onChangeType, onChangeEmail, onChangeAssign, validationErrors, allModules, onModuleSelected, defaultModule, options, onOptionsSelected, fieldOption, onCalendar } = props;
	const [selected, setSelected] = useState(defaultType == 'single');

	const [loadingPositions, setLoadingPositions] = useState(false);
	const [componentF, setComponentF] = useState(options?.componentF);
	const [componentO, setComponentO] = useState(options?.componentO);
	const [componentUO, setComponentUO] = useState(options?.componentUO);
	const [positionsToSet, setPositionsToSet] = useState(defaultPositions);
	const [moduleID, setModuleID] = useState(options?.module_id);
	const modulesArray = allModules.map(elem => {
		return { label: elem.name, value: elem.id, module: elem };
	});
	const { companyPositionsArray, companyDepartments } = useSelector(store => store.flowReducer);

	const [multiselectItems, setMultiselectItems] = useState(R.sortWith([R.ascend(R.prop('department_name'))])(companyPositionsArray?.map(i => ({
		label: i.label,
		value: i.value,
		department_name: i.department,
		department_id: companyDepartments.find(d => d.label == i.department)?.value,
	}))));

	useEffect(() => {

		const getPositions = getAllPositions().subscribe({
			next: dataPositions => {
				getCompanyDepartments().subscribe({
					next: dataDepartments => {
						const items = dataPositions?.map(i => ({
							label: i.name,
							value: i.id,
							department_name: dataDepartments.find(d => d.id == i.department_id)?.name,
							department_id: i.department_id,
						}));
						setMultiselectItems(R.sortWith([R.ascend(R.prop('department_name'))])(items));
						setLoadingPositions(false);
					},
					error: _error => {
						setLoadingPositions(false);
					},
				});
			},
			error: _error => {
				setLoadingPositions(false);
			},
		});

		return () => {
			controlledUnsubscription(getPositions);
		};
	}, []);
	return (
		<div className={cls.peopleContainer}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'How many People can be placed in this position?'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} app-container-variant={'noBorder'} className={classNames(cls.unfocusableWrapper)}>
					<RenderBooleansOptions
						selected={selected}
						setSelected={val => {
							setSelected(val);
							onChangeType?.(val ? 'single' : 'multiple');
						}}
						first={'One Person'}
						second={'Multiple People'}
						extraClassName={cls.renderBooleansOptions}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			{(componentF !== undefined || componentF?.length !== 0) && (
				<OptionSection errorMessage={validationErrors?.position_id}>
					<RenderTitle title={'Option 1: Display users from these positions:'} />
					<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
						{loadingPositions ? (
							<Spinner size={30} type={'ThreeDots'} />
						) : multiselectItems?.length > 0 ? (
							<MultiselectWithSearchR
								triggerType={'chevron'}
								labelsTag={true}
								tagCloseButtonType={'circleX' /* | "X" */}
								tagType={'shadowed' /* | "step-plated" | "plated" */}
								tagEmplacement={'inlined'}
								items={multiselectItems}
								itemType={'checkboxes'}
								checkboxEmplacement={'left'}
								initialSelectedItems={multiselectItems?.filter(i => defaultPositions?.indexOf(i.value) > -1)}
								className={cls.uploadSelectComponent}
								inputClassName={cls.uploadSearchInput}
								molclassname={cls.uploadSelectMenuOverlayList}
								moliclassname={cls.uploadSelectMenuOverlayListItem}
								onChange={selection => {
									setPositionsToSet(selection?.map(i => i?.value));
									onChangeList?.(selection?.map(i => i?.value));
								}}
							/>
						) : null}
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{positionsToSet.length == 0 && (
				<OptionSection errorMessage={validationErrors?.type}>
					<RenderTitle title={'Option 2: Display users based from a lookup in:'} />
					<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} className={cls.unfocusableWrapper}>
						<SelectD
							items={modulesArray}
							defaultSelected={defaultModule}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								setModuleID(selection.module.id);
								onModuleSelected?.(selection);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{positionsToSet.length == 0 && (
				<OptionSection>
					<RenderTitle title={'Field set in the steps where to lookup'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={componentF}
							name={'matched to field'}
							onChange={e => {
								setComponentF(e.target.value);
								onOptionsSelected({ ...fieldOption, componentF: e.target.value, module_id: moduleID });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{positionsToSet.length == 0 && (
				<OptionSection>
					<RenderTitle title={'Field from lookup where to get positions and users'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={componentO}
							name={'matched to field'}
							onChange={e => {
								setComponentO(e.target.value);
								onOptionsSelected({ ...fieldOption, componentO: e.target.value, module_id: moduleID });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{positionsToSet.length == 0 && (
				<OptionSection>
					<RenderTitle title={'Field from lookup where to get users (direct user list)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<input
							type={'text'}
							className={cls.dropDownNewListItemInput}
							value={componentUO}
							name={'matched to field'}
							onChange={e => {
								setComponentUO(e.target.value);
								onOptionsSelected({ ...fieldOption, componentUO: e.target.value });
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{!defaultCalendar && <RenderYesNoSwitcher
				{...{
					id: 'Display as slider',
					title: 'Display as slider?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultSlider ? true : false}
				toggleSelected={val => {
					onChangeSlider(val);
				}}
			/>}
			<RenderYesNoSwitcher
				{...{
					id: 'Trigger an email when user is selected',
					title: 'Trigger an email when user is selected?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultEmail ? true : false}
				toggleSelected={val => {
					onChangeEmail(val);
				}}
			/>
			<RenderYesNoSwitcher
				{...{
					id: 'Assign the user selected to transaction',
					title: 'Assign the user selected to transaction?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultAssign ? true : false}
				toggleSelected={val => {
					onChangeAssign(val);
				}}
			/>
			{!defaultSlider && <RenderYesNoSwitcher
				{...{
					id: 'Display as calendar',
					title: 'Display as calendar?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultCalendar ? true : false}
				toggleSelected={val => {
					onCalendar(val);
				}}
			/>}
		</div>
	);
});

export const PositionsSelect = memo(props => {
	const { defaultCalendar, defaultSlider, defaultAssign, defaultEmail, defaultType, defaultPositions, onChangeSlider, onChangeList, onChangeType, onChangeEmail, onChangeAssign, validationErrors, allModules, onModuleSelected, defaultModule, options, onOptionsSelected, fieldOption, onCalendar } = props;
	const [selected, setSelected] = useState(defaultType == 'single');

	const [loadingPositions, setLoadingPositions] = useState(false);
	const [componentF, setComponentF] = useState(options?.componentF);
	const [componentO, setComponentO] = useState(options?.componentO);
	const [componentUO, setComponentUO] = useState(options?.componentUO);
	const [positionsToSet, setPositionsToSet] = useState(defaultPositions);
	const [moduleID, setModuleID] = useState(options?.module_id);
	const modulesArray = allModules.map(elem => {
		return { label: elem.name, value: elem.id, module: elem };
	});
	const { companyPositionsArray, companyDepartments } = useSelector(store => store.flowReducer);

	const [multiselectItems, setMultiselectItems] = useState(R.sortWith([R.ascend(R.prop('department_name'))])(companyPositionsArray?.map(i => ({
		label: i.label,
		value: i.value,
		department_name: i.department,
		department_id: companyDepartments.find(d => d.label == i.department)?.value,
	}))));

	useEffect(() => {

		const getPositions = getAllPositions().subscribe({
			next: dataPositions => {
				getCompanyDepartments().subscribe({
					next: dataDepartments => {
						const items = dataPositions?.map(i => ({
							label: i.name,
							value: i.id,
							department_name: dataDepartments.find(d => d.id == i.department_id)?.name,
							department_id: i.department_id,
						}));
						setMultiselectItems(R.sortWith([R.ascend(R.prop('department_name'))])(items));
						setLoadingPositions(false);
					},
					error: _error => {
						setLoadingPositions(false);
					},
				});
			},
			error: _error => {
				setLoadingPositions(false);
			},
		});

		return () => {
			controlledUnsubscription(getPositions);
		};
	}, []);
	return (
		<div className={cls.peopleContainer}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'How many Positions can be selected?'} />
				<UnfocusableWrapper app-variant={validationErrors?.type ? 'error' : 'normal'} app-container-variant={'noBorder'} className={classNames(cls.unfocusableWrapper)}>
					<RenderBooleansOptions
						selected={selected}
						setSelected={val => {
							setSelected(val);
							onChangeType?.(val ? 'single' : 'multiple');
						}}
						first={'Single'}
						second={'Multiple'}
						extraClassName={cls.renderBooleansOptions}
					/>
				</UnfocusableWrapper>
			</OptionSection>
			{(componentF !== undefined || componentF?.length !== 0) && (
				<OptionSection errorMessage={validationErrors?.position_id}>
					<RenderTitle title={'Display these positions:'} />
					<UnfocusableWrapper app-variant={validationErrors?.position_id ? 'error' : 'normal'} className={classNames(cls.unfocusableWrapper, cls.peoplePositionSelectContainer)}>
						{loadingPositions ? (
							<Spinner size={30} type={'ThreeDots'} />
						) : multiselectItems?.length > 0 ? (
							<MultiselectWithSearchR
								triggerType={'chevron'}
								labelsTag={true}
								tagCloseButtonType={'circleX' /* | "X" */}
								tagType={'shadowed' /* | "step-plated" | "plated" */}
								tagEmplacement={'inlined'}
								items={multiselectItems}
								itemType={'checkboxes'}
								checkboxEmplacement={'left'}
								initialSelectedItems={multiselectItems?.filter(i => defaultPositions?.indexOf(i.value) > -1)}
								className={cls.uploadSelectComponent}
								inputClassName={cls.uploadSearchInput}
								molclassname={cls.uploadSelectMenuOverlayList}
								moliclassname={cls.uploadSelectMenuOverlayListItem}
								onChange={selection => {
									setPositionsToSet(selection?.map(i => i?.value));
									onChangeList?.(selection?.map(i => i?.value));
								}}
							/>
						) : null}
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{!defaultSlider && <RenderYesNoSwitcher
				{...{
					id: 'Display as calendar',
					title: 'Display as calendar?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={defaultCalendar ? true : false}
				toggleSelected={val => {
					onCalendar(val);
				}}
			/>}
		</div>
	);
});
export const SmartQuery = props => {
	const { defaultCurrent, defaultFixed, defaultDescription, defaultValue, defaultModule, defaultModule2, defaultModule3, allModules, onModule1Select, onModule2Select, onModule3Select, setFieldValue, setDefaultDescription, setDefaultFixed, setDefaultCurrent } = props;

	const [fixed, setFixed] = useState(defaultFixed ?? false);
	const [value, setValue] = useState(defaultValue);
	const [description, setDescription] = useState(defaultDescription);
	const [show, setShow] = useState(defaultCurrent == true ? false : defaultModule?.label !== '' ? true : false);
	const [current, setCurrent] = useState(defaultCurrent);

	const modulesArray = allModules.map(elem => {
		return { label: elem.name, value: elem.id, module: elem };
	});

	return (
		<Fragment>
			<OptionSection>
				<RenderTitle title={'Description'} />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<textarea
						type={'text'}
						className={cls.elementTextArea}
						value={description}
						name={'element-desc'}
						onChange={e => {
							setDefaultDescription(e.target.value);
							setDescription(e.target.value);
						}}
					/>
				</div>
			</OptionSection>
			<RenderYesNoSwitcher
				{...{
					id: 'Use records for the AI to analyze',
					title: 'Use records for the AI to analyze?',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
				value={show ? true : false}
				toggleSelected={val => {
					setShow(val);
					if (val == true) {
						setCurrent(false);
						setDefaultCurrent(false);
					}
				}}
			/>
			{!show && (
				<RenderYesNoSwitcher
					{...{
						id: 'Use current transaction for the AI to analyze',
						title: 'Use current transaction for the AI to analyze',
						ungrouped: true,
						withAckButton: false,
					}}
					style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
					value={current ? true : false}
					toggleSelected={val => {
						setCurrent(val);
						setDefaultCurrent(val);
					}}
				/>
			)}
			{show && (
				<OptionSection>
					<RenderTitle title={'Records to use as source A'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectD
							items={modulesArray}
							defaultSelected={defaultModule}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								onModule1Select?.(selection);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{show && (
				<OptionSection>
					<RenderTitle title={'Records to use as source B (Optional)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectD
							items={modulesArray}
							defaultSelected={defaultModule2}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								onModule2Select?.(selection);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}

			{show && (
				<OptionSection>
					<RenderTitle title={'Records to use as source C (Optional)'} />
					<UnfocusableWrapper className={cls.unfocusableWrapper}>
						<SelectD
							items={modulesArray}
							defaultSelected={defaultModule3}
							className={cls.dropdownSelectComponent}
							tmiClassName={cls.dropdownInput}
							molclassname={cls.dropdownSelectMenuOverlayList}
							moliclassname={cls.dropdownSelectMenuOverlayListItem}
							onChange={selection => {
								onModule3Select?.(selection);
							}}
						/>
					</UnfocusableWrapper>
				</OptionSection>
			)}
			{fixed == true && (
				<OptionSection>
					<RenderTitle title={`Fixed query to use ${show ? 'with the records selected' : ''}`} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<textarea
							type={'text'}
							className={cls.elementTextArea}
							value={value}
							name={'element-query'}
							onChange={e => {
								setFieldValue(e.target.value);
								setValue(e.target.value);
							}}
						/>
					</div>
				</OptionSection>
			)}
			<RenderYesNoSwitcher
				{...{
					id: 'Use a fixed query?',
					title: 'Use a fixed query',
					ungrouped: true,
					withAckButton: false,
				}}
				style={{ margin: '20px 0px 0px 0px', width: 'auto' }}
				value={fixed}
				toggleSelected={val => {
					setFixed(val);
					setDefaultFixed(val);
				}}
			/>
		</Fragment>
	);
};

export const IdGenerator = props => {
	const { onSetIdGenerator, defaultValue } = props;
	const [value, setValue] = useState(defaultValue.label);
	const setFieldValue = value => onSetIdGenerator(value);

	return (
		<div className={cls.currencySelectContainer}>
			<input
				type={'text'}
				placeholder={'Enter prefix of ID'}
				className={cls.elementTextInput}
				value={value}
				name={'element-name'}
				onChange={e => {
					setValue(e.target.value);
					setFieldValue(e.target.value);
				}}
			/>
		</div>
	);
};
export const NotesGenerator = props => {
	const { onNotesGenerator, defaultValue } = props;
	const [value, setValue] = useState(defaultValue.label);
	const setFieldValue = value => onNotesGenerator(value);

	return (
		<div className={cls.currencySelectContainer}>
			<input
				type={'text'}
				placeholder={'Enter Notes to display'}
				className={cls.elementTextInput}
				value={value}
				name={'element-name'}
				onChange={e => {
					setValue(e.target.value);
					setFieldValue(e.target.value);
				}}
			/>
		</div>
	);
};
export const RenderTutorialRectangle = props => {
	const { setShowTutorial } = props;

	return (
		<div className="tutorial-rectangle-container">
			<div
				onClick={() => {
					setShowTutorial?.(true);
				}}
				className="tutorial-rectangle"
			>
				<img alt="" src={play} />
				<div>
					<p>{'Tutorial'}</p>
					<p style={{ fontSize: '0.75rem' }}>{'1:12 min'}</p>
				</div>
			</div>
		</div>
	);
};
export const DropdownSelectPayments = props => {
	const { defaultList, defaultSrcName, onSourceLabel, defaultValue, validationErrors, onSourceSelected, bpContext, onListSelected = () => { } } = props;
	const { isOnboarding } = useSelector(store => store.modalsReducer);
	const [list, setList] = useState(defaultList ?? []);
	const [newItem, setNewItem] = useState('');
	const [selectedFieldsArray, setSelectedFieldsArray] = useState([]);
	const [showAddOption, setShowAddOption] = useState(true);

	const [componentsBlueprintArray, setComponentsBlueprintArray] = useState([]);

	useEffect(() => {
		retreiveComponents();
	}, []);

	const retreiveComponents = useCallback(() => {
		setComponentsBlueprintArray(
			isOnboarding ? bpContext.allBlueprintComponents
				.map(elem => {
					return { label: elem.name, value: elem.id, blueprint: elem };
				})
				:
				bpContext.allBlueprintComponents
					.filter(bpc => bpc.readonly === false)
					.map(elem => {
						return { label: elem.name, value: elem.id, blueprint: elem };
					})
		);
	});
	const onAddNewListItem = useCallback(() => {
		setShowAddOption(true);
		setList(c => [...c, newItem]);
		setNewItem('');
		onListSelected?.([...list, newItem]);
	}, [list, newItem, onListSelected]);

	const onRemoveListItem = useCallback(
		item => {
			setList(c => [...R.reject(R.equals(item), c)]);
			onListSelected?.([...R.reject(R.equals(item), list)]);
		},
		[list, onListSelected]
	);
	return (
		<div className={cls.dropdownElementHost}>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Select Component where field to use as payment amount is found'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={componentsBlueprintArray}
						defaultSelectedItem={defaultValue}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							const cmp = componentsBlueprintArray.find(cb => cb.value === selection.value);
							setSelectedFieldsArray(
								cmp.blueprint.fields
									.filter(bf => bf.name === 'Number' || bf.name === 'Formula')
									.map(f => {
										return { label: f.value, value: f.id, field: f };
									})
							);
							onSourceSelected(selection.value);
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.type}>
				<RenderTitle title={'Field to use for payment amount'} />
				<div className={cls.currencySelectContainer}>
					<SelectWithSearch
						triggerType={'chevron'}
						items={selectedFieldsArray}
						defaultSelectedItem={defaultSrcName}
						className={cls.componentsSelectComponent}
						inputClassName={cls.componentsSearchInput}
						inputStyle={{ color: 'var(--app-color-gray-dark)', fontSize: '1rem' }}
						clearControlsClassName={cls.componentsClearControlsClassName}
						molclassname={cls.componentsSelectMenuOverlayList}
						moliclassname={cls.componentsSelectMenuOverlayListItem}
						onChange={selection => {
							onSourceLabel(selection.label);
						}}
					/>
				</div>
			</OptionSection>
			<OptionSection errorMessage={validationErrors?.list}>
				<RenderTitle title={'Payment Options'} />
				<UnfocusableWrapper
					app-variant={validationErrors?.list ? 'error' : 'normal'}
					className={cls.dropdownListContainer}
					onClickOutside={useCallback(() => {
						onListSelected?.(list);
					}, [list, onListSelected])}
				>
					{showAddOption ? (
						<div className={cls.dropDownButtonContainer}>
							<Button light title={'Add Payment Option'} clicked={() => setShowAddOption(false)} />
						</div>
					) : (
						<div className={cls.dropDownNewListItemInputContainer}>
							<input
								type={'text'}
								className={cls.dropDownNewListItemInput}
								value={newItem}
								name={'newItem'}
								placeholder={'Type name'}
								onChange={e => {
									setNewItem(e.target.value);
								}}
							/>
							{newItem?.length > 0 && (
								<button type={'button'} onClick={onAddNewListItem} className={cls.dropdownInputButtonSaveNewItem}>
									<FontAwesomeIcon icon={faSquarePlus} />
								</button>
							)}
						</div>
					)}
					<div className={cls.dropdownListItemsContainer}>
						{list &&
							list?.map((item, idx) => (
								<RenderEditableListItem
									key={item + idx}
									item={item}
									isOverflow={list?.length > 3}
									onItemChanged={newItem => {
										setList(c => [...R.update(idx)(newItem)(c)]);
									}}
									onItemRemoved={() => onRemoveListItem(item)}
								/>
							))}
					</div>
				</UnfocusableWrapper>
			</OptionSection>
		</div>
	);
};
